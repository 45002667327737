import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import CURRENCIES from 'qonto/constants/currencies';
import {
  CONTRACT_STATUS,
  PAY_LATER_ELIGIBILITY_STATUSES,
  PAY_LATER_FLOW_ORIGIN,
} from 'qonto/constants/financing';
import {
  OPERATION_TYPES,
  SEPA_TRACKING_SETTLEMENT_CTA,
  SEPA_TRACKING_SETTLEMENT_EVENTS,
  STATUS,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { getCurrentParisDateString } from 'qonto/utils/date';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
  createSepaSettlementTrackingEventPayload,
} from 'qonto/utils/transfers';

class SepaPayLaterTransferFlowDataContext {
  sepaTransfer = null;
  beneficiary = null;
  invoice = null;
  origin = null;
  supplierInvoiceId = null;
  isInstantFallback = false;
  confirmationResult = {};
  settlementResult = {};
  budgetName = null;
  availableCreditAmount = null;
  isPayLaterEligible = false;
  isPayLaterContractSigned = false;
  payLaterInstallments = null;
  defaultOperationType = OPERATION_TYPES.PAY_LATER;
  validationError = null;
  invoiceAttachmentLimits = null;
  minTransferAmount = null;
  isDedicatedFlow = true;
  signature = { url: '', status: '' };
  isMainAccountNotSelected = false;
  isSchedulingOrRecurrenceEnabled = false;
  didValidateFinancing = false;
  shouldPerformDefaultRedirection = false;

  constructor({ sepaTransfer, invoice, origin, beneficiary = null }) {
    this.sepaTransfer = sepaTransfer;
    this.invoice = invoice;
    this.origin = origin;
    this.beneficiary = beneficiary;
  }
}

export default class SepaPayLaterTransferFlowSetup extends FlowSetup {
  @service abilities;
  @service beneficiariesManager;
  @service errors;
  @service flow;
  @service flowLinkManager;
  @service financing;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service payByInvoiceUploadManager;
  @service router;
  @service segment;
  @service store;
  @service toastFlashMessages;

  constructor(_owner, previousDataContext = {}) {
    super(...arguments);

    let { organization } = this.organizationManager;
    let { mainAccount } = organization;
    let { queryParams } = this.router.currentRoute;
    let { origin } = queryParams;
    let { beneficiary, invoice, sepaTransfer } = previousDataContext;

    let sepaTransferRecord =
      sepaTransfer ||
      this.store.createRecord('transfer', {
        activityTag: 'other_expense',
        amountCurrency: CURRENCIES.default,
        bankAccount: mainAccount,
        fx: false,
        organization,
        operationType: OPERATION_TYPES.PAY_LATER,
      });

    sepaTransferRecord.addIdempotencyKey();

    this.dataContext = new SepaPayLaterTransferFlowDataContext({
      sepaTransfer: sepaTransferRecord,
      beneficiary,
      invoice,
      origin,
    });

    if (beneficiary) {
      this.setTransferBeneficiary(beneficiary);
    }

    this.segment.track('transfer-sepa_flow_started', {
      ...(origin && { origin }),
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');

    if (this.abilities.cannot('create pay later transfer in financing')) {
      return this.homePage.replaceWithDefaultPage();
    }

    try {
      let {
        eligibility,
        contractStatus,
        availableCreditAmount,
        invoiceAttachmentLimits,
        minTransferAmount,
      } = await this.financing.checkPayLaterEligibility();

      if (
        eligibility !== PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE ||
        Number(availableCreditAmount?.value) < Number(minTransferAmount?.value)
      ) {
        return this.homePage.replaceWithDefaultPage();
      }

      this.dataContext = {
        ...this.dataContext,
        availableCreditAmount,
        isPayLaterContractSigned: contractStatus === CONTRACT_STATUS.SIGNED,
        isPayLaterEligible: eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE,
        invoiceAttachmentLimits,
        minTransferAmount,
      };
    } catch {
      return this.homePage.replaceWithDefaultPage();
    }

    let { queryParams } = this.router.currentRoute;
    let { invoiceId, transferId, reset } = queryParams;

    if (invoiceId) {
      this.dataContext.invoice = this.store.peekRecord('supplier-invoice', invoiceId);

      if (
        variation('feature--boolean-pay-later-modular-flow-supplier-invoices') &&
        !transferId &&
        this.dataContext.invoice
      ) {
        let attachmentId = this.dataContext.invoice.belongsTo('attachment').id();
        let attachment = await this.store.findRecord('attachment', attachmentId);
        let { iban, invoiceNumber, supplierName, totalAmount, supplierSnapshot } =
          this.dataContext.invoice;

        if (iban || supplierSnapshot?.iban) {
          let matchingBeneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
            this.organizationManager.organization.id,
            iban || supplierSnapshot?.iban
          );
          if (
            matchingBeneficiary &&
            (variation('feature--boolean-supplier-hub') ||
              matchingBeneficiary?.name === supplierName)
          ) {
            this.setTransferBeneficiary(matchingBeneficiary);
          }
        }

        this.dataContext.sepaTransfer.amount = totalAmount ? totalAmount.value : null;
        this.dataContext.sepaTransfer.attachments = attachment ? [attachment] : undefined;
        this.dataContext.sepaTransfer.reference = invoiceNumber ?? undefined;
      }
    }

    if (transferId) {
      this.usePreviousTransfer(transferId);
    }

    if (reset) {
      this.dataContext.shouldPerformDefaultRedirection = true;
    }

    // clear query parameters
    this.router.replaceWith({
      queryParams: { ...queryParams, invoiceId: null, transferId: null, reset: null },
    });
  }

  @action
  onComplete({ invoice, origin, sepaTransfer, settlementResult }) {
    if (settlementResult) {
      let { status, declinedReason } = settlementResult;
      let isQontoBeneficiary = sepaTransfer.get('beneficiary.qontoBankAccount');

      let eventName;

      if (status === STATUS.DECLINED) {
        eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED;
      } else if (status === STATUS.PROCESSING) {
        eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.LOADING_CLOSED;
      } else if (status === 'timeout') {
        eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.TIMEOUT_CLOSED;
      } else {
        eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED;
      }

      let payload = createSepaSettlementTrackingEventPayload({
        invoice,
        origin,
        sepaTransfer,
        settlementStatus: status,
        declinedReason,
        cta: SEPA_TRACKING_SETTLEMENT_CTA.EXIT,
        isQontoBeneficiary,
      });

      this.segment.track(eventName, payload);
    }

    this.payByInvoiceUploadManager.resetState();
    this.handleRedirect(origin);
  }

  onAbortTask = dropTask(async ({ origin }, { id: stepId }) => {
    if (stepId !== 'invoice-upload') {
      let result = await this.openAbortModalTask.perform();
      if (result !== 'confirm') return false;
    }

    this.payByInvoiceUploadManager.resetState();

    this.segment.track('transfer_creation_exited', { origin });

    if (
      origin === PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY &&
      !this.dataContext.shouldPerformDefaultRedirection &&
      variation('feature--boolean-pay-later-modular-flow-pay-by-beneficiary')
    ) {
      this.flowLinkManager.transitionTo({
        name: 'sepa-transfer',
        stepId: 'details',
        queryParams: {
          origin: TRANSFER_FLOW_ORIGIN.PAY_LATER_FLOW,
          payLaterTransferId: this.dataContext.sepaTransfer.idempotencyKey,
        },
      });
    } else {
      this.handleRedirect(origin);
    }

    return true;
  });

  openAbortModalTask = dropTask(
    async () =>
      await this.modals.open('popup/destructive', {
        title: this.intl.t('transfers.exit-flow.title'),
        description: this.intl.t('transfers.exit-flow.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('transfers.exit-flow.confirm'),
      })
  );

  handleRedirect(origin) {
    let redirectToRoute;

    switch (origin) {
      case TRANSFER_FLOW_ORIGIN.OVERVIEW:
        redirectToRoute = 'overview.index';
        break;
      case TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE:
        redirectToRoute = 'transfers.landing';
        break;
      case TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES:
      case PAY_LATER_FLOW_ORIGIN.SUPPLIER_INVOICES:
        redirectToRoute = 'supplier-invoices.index';
        break;
      default:
        redirectToRoute = TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT;
    }
    this.router.replaceWith(redirectToRoute);
  }

  usePreviousTransfer(id) {
    let transferRecord = this.store.peekAll('transfer').find(t => t.idempotencyKey === id);

    if (!transferRecord) return;

    let { organization } = this.organizationManager;
    let { mainAccount } = organization;
    let { sepaTransfer } = this.dataContext;

    this.prepareDisplayDisclaimer(transferRecord, mainAccount);

    transferRecord.operationType = sepaTransfer.operationType;
    transferRecord.bankAccount = mainAccount;
    transferRecord.scheduledDate = getCurrentParisDateString();

    this.dataContext.sepaTransfer = transferRecord;
  }

  prepareDisplayDisclaimer(transfer, mainAccount) {
    if (
      variation('feature--boolean-pay-later-modular-flow-pay-by-beneficiary') &&
      this.dataContext.origin === PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY
    ) {
      if (transfer.bankAccount !== mainAccount) {
        this.dataContext.isMainAccountNotSelected = true;
      }
      if (transfer.scheduledDate !== getCurrentParisDateString()) {
        this.dataContext.isSchedulingOrRecurrenceEnabled = true;
      }
    }

    if (
      this.dataContext.origin === TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE ||
      (variation('feature--boolean-pay-later-modular-flow-supplier-invoices') &&
        this.dataContext.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES)
    ) {
      if (transfer.bankAccount !== mainAccount) {
        this.dataContext.isMainAccountNotSelected = true;
      }
    }
  }

  setTransferBeneficiary(beneficiary) {
    let { sepaTransfer } = this.dataContext;
    sepaTransfer.set('beneficiary', beneficiary);

    copyBeneficiaryIntoTransfer(sepaTransfer, beneficiary, { forceCopy: true });
    copyBeneficiaryLabelsIntoTransfer(sepaTransfer, beneficiary);
    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(sepaTransfer, beneficiary);
    }
  }
}
