export default {
  "header": "qvj",
  "btn-preset": "qvW btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qvu",
  "statements-table": "qoS",
  "no-result": "qoc",
  "lottie-illustration": "qoq",
  "wrapper": "qoZ",
  "access-denied": "qoR",
  "access-denied-illustration": "qoQ",
  "application-card": "qoe",
  "presets-wrapper": "qoB",
  "error-wrapper": "qoE",
  "error-container": "qod",
  "error-illustration": "qoI mb-32",
  "invoicing-info": "qot"
};
