import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AgenciaTributariaRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    if (this.abilities.cannot('view nrc')) {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
