export default {
  "container": "qun",
  "wrapper": "qui",
  "grey-header-block": "quA",
  "connect-app-header": "quY",
  "connect-app-header-link": "quh",
  "connect-app-header-content": "quf",
  "connect-app-header-content-default": "quK",
  "connect-app-header-content-left": "quG",
  "button-block": "qur",
  "connect-app-body": "qub",
  "connect-app-body-left-content": "quy",
  "connect-app-body-sidebar": "quU"
};
