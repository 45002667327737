export default {
  "page-wrapper": "qoM",
  "container": "qoP",
  "sidebar": "qol",
  "search-input": "qoX",
  "organizations-list": "qoC",
  "content": "qok",
  "no-result": "qoJ",
  "lottie-illustration": "qos",
  "accounting-header": "qoF",
  "logo": "qog",
  "accounting-title": "qoD"
};
