/* eslint-disable @qonto/no-redirect-in-unrelated-hooks */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class OrganizationsRoute extends Route {
  @service sentry;
  @service abilities;
  @service router;
  @service featuresManager;
  @service organizationManager;
  @service subscriptionManager;
  @service banners;
  @service bannerFlashMessages;
  @service store;
  @service kycVerification;
  @service loginPerformanceMetrics;

  model(params) {
    let slug = params.organization_slug;
    let organization = this.organizationManager.getOrganizationBySlug(slug);
    if (organization && !organization.get('accessDisabled')) {
      return organization;
    }

    this.router.replaceWith('/404');
    return null;
  }

  async afterModel(organization, transition) {
    super.afterModel(...arguments, transition);

    // If the model is not loaded at this point could mean that the user was redirected to 404.
    if (!organization) {
      this.sentry.captureMessage('afterModel: organization is not loaded');
      return;
    }

    if (variation('feature--boolean-login-improvement')) {
      await this.organizationManager.setCurrentOrganizationAndMembership(organization);

      await Promise.all([
        this.subscriptionManager.refresh(organization),
        organization.loadTeams(),
        this.loadFlexKyb(organization),
      ]);
    } else {
      await Promise.all([
        this.organizationManager.setCurrentOrganizationAndMembership(organization),
        this.subscriptionManager.refresh(organization),
        organization.loadTeams(),
        this.loadFlexKyb(organization),
      ]);
    }

    let promises = [
      organization.loadBankAccounts(),
      ...(this.abilities.can('view connections external-account')
        ? [organization.loadBankConnections()]
        : []),
    ];

    let { kycAccepted, kycSubmitted } = this.organizationManager.membership;
    if (!kycAccepted || !kycSubmitted) {
      promises.push(
        this.kycVerification.generateUrl().catch(() => {
          // we don't want to block the user
        })
      );
    }

    await Promise.all(promises);

    // Hide dual nav animations on load, signin, org change
    document.body.classList.add('prevent-nav-animation');
  }

  serialize(model) {
    // in some cases `model` can be undefined. in these cases we fall back to
    // the current route model to get the current organization slug.
    return { organization_slug: (model || this.currentModel).get('slug') };
  }

  async loadFlexKyb(organization) {
    if (organization.isDeFreelancer || organization.isDeCompanyCreation) {
      await organization.loadFlexKyb();
    }
  }

  activate() {
    let { underRegistration } = this.organizationManager.organization;
    if (underRegistration) {
      this.bannerFlashMessages.clearTopBannerMessages();
    }

    this.router.on('routeDidChange', this, this._triggerBanners);

    if (this.router.currentURL === '/signin') {
      this.loginPerformanceMetrics.trackLoginPerformance({ destination: 'organizations' });
    }
  }

  deactivate() {
    this.bannerFlashMessages.clearTopBannerMessages();
    this.router.off('routeDidChange', this, this._triggerBanners);
  }

  _triggerBanners() {
    this.banners.triggerBanners();
  }
}
