import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { all, dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { labelOverTimeAggregations, monthlyAggregation } from 'qonto/constants/cash-flow';
import { TablesLayout } from 'qonto/react/components/cash-flow/components/tables-layout';
import { ErrorInfo } from 'qonto/utils/error-info';
import buildQuery from 'qonto/utils/statistics';

const MAX_PERIOD_LENGTH = 12;
const DISPLAYED_MONTHS = 6;
const DISPLAYED_MONTHS_CASHFLOW_CATEGORIES = 4;

export default class CashFlowIndexController extends Controller {
  tablesLayout = TablesLayout;
  @service abilities;
  @service store;
  @service organizationManager;
  @service cashFlowTimeseriesManager;
  @service sentry;

  @reads('organizationManager.organization.id') organizationId;

  @tracked indexOffset = 0;
  @tracked selectedPeriod;
  @tracked chartStatistics = [];
  @tracked chartComparisonStatistics = [];

  get displayedMonths() {
    return this.abilities.can('assign category')
      ? DISPLAYED_MONTHS_CASHFLOW_CATEGORIES
      : DISPLAYED_MONTHS;
  }

  get bankAccounts() {
    return this.model.bankAccounts;
  }

  get adapter() {
    return this.store.adapterFor('transaction');
  }

  get isChartLoading() {
    return this.getStatisticsTask.isRunning;
  }

  get isTimeseriesLoading() {
    return this.cashFlowTimeseriesManager.fetchTimeseriesTask.isRunning;
  }

  get isTimeseriesError() {
    return this.cashFlowTimeseriesManager.fetchTimeseriesTask.last?.isError;
  }

  get isChartError() {
    return this.getStatisticsTask.last?.isError;
  }

  get isTableLoading() {
    return (
      this.getLabelCashflowStatisticsTask.isRunning ||
      this.getUnlabeledCashflowStatisticsTask.isRunning
    );
  }

  get isTableError() {
    return (
      this.getLabelCashflowStatisticsTask.last?.isError ||
      this.getUnlabeledCashflowStatisticsTask.last?.isError
    );
  }

  get isLoading() {
    if (this.abilities.cannot('assign category')) {
      return this.isChartLoading || this.isTableLoading;
    }

    return this.isTimeseriesLoading;
  }

  get isError() {
    if (this.abilities.cannot('assign category')) {
      return this.isChartError || this.isTableError;
    }

    return this.isTimeseriesError;
  }

  get labelStatistics() {
    return this.getLabelCashflowStatisticsTask.last?.value;
  }

  get unlabeledStatistics() {
    return this.getUnlabeledCashflowStatisticsTask.last?.value;
  }

  @action onNextMonth() {
    if (this.indexOffset >= MAX_PERIOD_LENGTH - this.displayedMonths) return;

    this.indexOffset = this.indexOffset + 1;
  }

  @action onPreviousMonth() {
    if (this.indexOffset === 0) return;

    this.indexOffset = this.indexOffset - 1;
  }

  @action onResetOffset() {
    this.indexOffset = 0;
  }

  queryStatisticsTask = dropTask(
    async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
      try {
        if (this.abilities.can('assign category')) {
          this.chartStatistics = await this.cashFlowTimeseriesManager.fetchTimeseriesTask.perform(
            basePeriod,
            bankAccounts
          );
        } else {
          await this.getStatisticsTask
            .linked()
            .perform(aggregations, basePeriod, bankAccounts, comparisonPeriod);
        }

        this._setControlsState(basePeriod);

        if (this.abilities.can('assign category')) {
          await this.getLabelCashflowStatisticsTask.linked().perform(basePeriod, bankAccounts);
          await this.getUnlabeledCashflowStatisticsTask.linked().perform(basePeriod, bankAccounts);
        }
      } catch (error) {
        this.captureError(error);
      }
    }
  );

  getStatisticsTask = dropTask(async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
    let isPeriod = period => period?.interval || period?.startDate || period?.endDate;

    let getQuery = (period, side) =>
      buildQuery(this.organizationId, aggregations, period, side, bankAccounts);

    let getStatistics = period => [
      this.adapter.getCashflow(getQuery(period, 'credit')),
      this.adapter.getCashflow(getQuery(period, 'debit')),
      this.adapter.getBalance(getQuery(period)),
    ];

    this.chartStatistics = await all(getStatistics(basePeriod));

    if (isPeriod(comparisonPeriod)) {
      this.chartComparisonStatistics = await all(getStatistics(comparisonPeriod));
    }
  });

  getLabelCashflowStatisticsTask = dropTask(async (period, bankAccounts) => {
    try {
      let getLabelsQuery = side =>
        this.adapter.getCashflow(
          buildQuery(this.organizationId, labelOverTimeAggregations, period, side, bankAccounts)
        );

      return await all([getLabelsQuery('credit'), getLabelsQuery('debit')]);
    } catch (error) {
      this.captureError(error);
    }
  });

  getUnlabeledCashflowStatisticsTask = dropTask(async (period, bankAccounts) => {
    try {
      let getUnlabeledQuery = side =>
        this.adapter.getCashflow(
          buildQuery(this.organizationId, monthlyAggregation, period, side, bankAccounts, {
            property: 'label_list_ids',
            operator: 'not_in',
            values: this.organizationManager.organization.labelLists.map(list => list.id),
          })
        );

      return await all([getUnlabeledQuery('credit'), getUnlabeledQuery('debit')]);
    } catch (error) {
      this.captureError(error);
    }
  });

  captureError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(errorInfo.error);
    }
  }

  reload() {
    window.location.reload();
  }

  _setControlsState(basePeriod) {
    let inflows = this.chartStatistics[0];
    let currentDateIndex = inflows.data.display_at_monthly.findIndex(({ start_date, end_date }) => {
      return dayjs().isBetween(dayjs(start_date), dayjs(end_date), 'day', '[]');
    });

    if (currentDateIndex >= this.displayedMonths - 1) {
      this.indexOffset = currentDateIndex - (this.displayedMonths - 1);
    }
    this.selectedPeriod = basePeriod;
  }
}
