export default {
  "page-wrapper": "qiK",
  "back-button": "qiG",
  "abort-button": "qir",
  "container": "qib",
  "subtitle": "qiy",
  "button": "qiU",
  "panel-list": "qij",
  "panel-list-item": "qiW",
  "card-illustration": "qiu"
};
