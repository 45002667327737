export default {
  "wrapper": "ZSW",
  "integrations": "ZSu",
  "description": "ZcS body-2",
  "api": "Zcc flex flex-column",
  "api-container": "Zcq flex large",
  "api-input": "ZcZ large",
  "api-btn": "ZcR",
  "key-input": "ZcQ"
};
