export default {
  "container": "qjC",
  "form-container": "qjk",
  "preview-container": "qjJ",
  "pdf-preview-container": "qjs",
  "content": "qjF",
  "title": "qjg",
  "tabs-container": "qjD",
  "close-button": "qjV btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qjL",
  "header": "qjT",
  "without-tabs": "qjz"
};
