import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class OauthRoute extends Route {
  @service refreshSessionManager;
  @service sessionManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');
  }

  async afterModel() {
    if (this.sessionManager.isAuthenticated) {
      await this.sessionManager.setupData();
    }
  }

  activate() {
    this.refreshSessionManager.startActivityTracking();
  }

  deactivate() {
    this.refreshSessionManager.cancelActivityTracking();
  }
}
