import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import CURRENCIES from 'qonto/constants/currencies';
import { MIGRATION_CUT_OFF_DATE } from 'qonto/constants/products';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class QuoteEditRoute extends Route {
  @service abilities;
  @service intl;
  @service toastFlashMessages;
  @service menu;
  @service organizationManager;
  @service router;
  @service store;
  @service sentry;

  beforeModel() {
    if (!this.abilities.can('update receivableInvoice')) {
      return this.router.replaceWith('receivable-invoices');
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model({ id }, transition) {
    try {
      let { organization } = this.organizationManager;

      let settings, documentItems;

      try {
        [settings] = await Promise.all([
          this.store.findRecord('receivable-invoices-settings', organization.id, {}),
        ]);

        documentItems = await this.fetchDocumentItemsTask
          .perform()
          .catch(ignoreCancelation)
          .catch(error => this.ignoreNotFoundAndHandleError(error));
      } catch (error) {
        if (
          ErrorInfo.for(error).shouldSendToSentry &&
          !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
        ) {
          this.sentry.captureException(error);
        }

        if (error instanceof NotFoundError) {
          return;
        }
        this._redirectOnError();
      }

      let editedQuote = await this.store.findRecord('quote', id);

      editedQuote.contactEmail = settings.contactEmail;
      editedQuote.withholdingTax =
        editedQuote.withholdingTax ?? this.store.createRecord('receivable-invoice/withholding-tax');
      editedQuote.welfareFund =
        editedQuote.welfareFund ?? this.store.createRecord('receivable-invoice/welfare-fund');

      editedQuote.organization.vatNumber = settings.vatNumber;

      if (organization.legalCountry === 'DE') {
        editedQuote.header = settings.quoteHeader;
        editedQuote.footer = settings.quoteFooter;
        editedQuote.organization.taxNumber = settings.taxNumber;
        editedQuote.organization.companyLeadership = settings.companyLeadership;
        editedQuote.organization.districtCourt = settings.districtCourt;
        editedQuote.organization.commercialRegisterNumber = settings.commercialRegisterNumber;
      }

      if (variation('feature--boolean-client-hub')) {
        await this.fetchClientsTask
          .perform()
          .catch(ignoreCancelation)
          .catch(error => this.ignoreNotFoundAndHandleError(error));
      }

      await this.store.query('customer', {
        filter: { organization_id: organization.id },
      });

      await editedQuote.customer.catch(error => {
        let client = this.store.peekRecord('client-hub', editedQuote.belongsTo('customer').id());
        if (variation('feature--boolean-client-hub') && client) {
          editedQuote.customer = client;
        } else {
          editedQuote.customer = null;
        }

        this.handleError(error);
      });

      this.fetchOrganizationAvatarTask.perform(organization).catch(ignoreCancelation);

      editedQuote.currency =
        editedQuote?.customer.get('currency') || editedQuote.currency || CURRENCIES.default;

      let quote = {};

      // when coming from the settings modal, there might be already some changed applied to the opened edited quote
      // instead of initializing fetching the edited one, we will peek in the store the the one with the latest changes
      if (transition?.from?.name === 'invoicing-settings' && this.peekRecordedQuotes.length > 0) {
        // only one newly created quote can be expected inside the array
        quote = this.peekRecordedQuotes[0];

        // the email needs to be the latest one
        quote.contactEmail = settings.contactEmail;
      } else {
        quote = editedQuote;
      }
      return { quote, settings, documentItems };
    } catch (error) {
      this.handleError(error);
      this._redirectOnError();
    }
  }

  get peekRecordedQuotes() {
    // peek in the store the already created but not saved quote without an id
    return this.store.peekAll('quote').filter(quote => quote.isNew && quote.id === null);
  }

  @action
  willTransition(transition) {
    if (
      !transition.targetName.includes('quotes.edit') &&
      !transition.targetName.includes('invoicing-settings')
    ) {
      let { quote } = this.context;
      quote.rollbackAttributes();
      quote.items.forEach(item => item.rollbackAttributes());
    }
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchDocumentItemsTask = restartableTask(async () => {
    let documentItemsParams = {
      filter: {
        document_types: 'invoices,quotes,credit_notes',
        created_at_to: variation('feature-invoices-catalog-of-items')
          ? MIGRATION_CUT_OFF_DATE
          : undefined,
      },
      page: { size: 200, number: 1 },
      sort: '-total_documents,title,-unit_price',
    };
    let documentItems = await this.store.query('document-item', documentItemsParams);
    let total = documentItems.meta.total;

    while (total > documentItems.length) {
      documentItemsParams.page.number++;
      let nextDocumentItems = await this.store.query('document-item', documentItemsParams);
      documentItems = documentItems.concat(nextDocumentItems);
    }

    return documentItems;
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.router.transitionTo('quotes.index');
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }
}
