import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CARD_STATUSES_INSURED, CARD_TYPES } from 'qonto/constants/cards';

const PHYSICAL_CARDS_RANKS = {
  standard: 1,
  plus: 2,
  metal: 3,
};

export default class InsurancesRoute extends Route {
  @service organizationManager;
  @service store;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');
  }

  async model() {
    let insuredCards = await this.store.query('card', {
      filters: {
        holder_id: this.organizationManager.membership.id,
        card_levels: CARD_TYPES.PHYSICALS,
        statuses: CARD_STATUSES_INSURED,
      },
    });

    let highestCardLevel = [...insuredCards]
      .filter(cardRecord => cardRecord.isInsuranceCovered)
      .sort(
        (current, next) =>
          PHYSICAL_CARDS_RANKS[next.cardLevel] - PHYSICAL_CARDS_RANKS[current.cardLevel]
      )[0]?.cardLevel;

    return { highestCardLevel, legalCountry: this.organizationManager.organization.legalCountry };
  }
}
