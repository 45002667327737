export default {
  "details": "qrV",
  "header": "qrL",
  "avatar": "qrT mr-16",
  "header-beneficiary": "qrz",
  "header-beneficiary-activity": "qra",
  "header-beneficiary-details": "qrH",
  "header-beneficiary-details-recurring": "qrO",
  "infos": "qrm",
  "infos-title": "qrp",
  "infos-empty": "qrx",
  "infos-list": "qrw",
  "infos-list-item": "qrN",
  "infos-list-item-left": "qrv",
  "infos-list-item-right": "qro",
  "infos-list-item-attachment": "qrn",
  "infos-transactions": "qri",
  "actions": "qrA"
};
