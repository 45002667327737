export default {
  "container": "qyd",
  "content": "qyI",
  "logos": "qyt",
  "logo": "qyM",
  "animation": "qyP",
  "title": "qyl title-2 mb-32",
  "avatar": "qyX",
  "dropdown": "qyC",
  "disabled": "qyk",
  "organization": "qyJ",
  "actions": "qys"
};
