export default {
  "page-container": "qGV",
  "form": "qGL",
  "close-button": "qGT btn btn--icon-only btn--tertiary btn--large",
  "preview": "qGz",
  "preview-padding": "qGa",
  "title": "qGH",
  "header": "qGO",
  "without-tabs": "qGm",
  "form-section": "qGp",
  "form-footer": "qGx",
  "disclaimer-text": "qGw"
};
