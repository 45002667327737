export default {
  "wrapper": "qGW",
  "header": "qGu",
  "main": "qrS",
  "frequency": "qrc body-1",
  "radiogroup": "qrq",
  "divider": "qrZ",
  "option-wrapper": "qrR",
  "option-details-wrapper": "qrQ",
  "option-label": "qre",
  "option-name": "qrB",
  "option-details-text": "qrE",
  "spinner-wrapper": "qrd",
  "spinner": "qrI"
};
