import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { CLAIM_STATUSES } from 'qonto/constants/transactions';

export class ChargebackClaimDataContext {
  card = null;
  claimType = null;
  files = new TrackedArray([new TrackedArray([]), new TrackedArray([]), new TrackedArray([])]);
  selectedTransactions = null;
  transaction = null;
}

export default class ChargebackClaimFlowSetup {
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service store;

  dataContext = null;

  constructor(owner) {
    setOwner(this, owner);
    this.dataContext = new ChargebackClaimDataContext();
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    let { organization } = this.organizationManager;

    let { claimType, transactionId } = this.router.currentRoute.queryParams;
    if (!claimType || !transactionId) {
      return this.router.transitionTo('transactions.index', organization);
    }

    let transactionRecord = this.store.peekRecord('transaction', transactionId);
    if (!transactionRecord) {
      return this.router.transitionTo('transactions.index', organization);
    }

    this.dataContext.card = transactionRecord.belongsTo('subject').value();
    this.dataContext.claimType = claimType;
    this.dataContext.queryParams = this.router.currentRoute.queryParams;
    this.dataContext.selectedTransactions = new TrackedArray([transactionRecord]);
    this.dataContext.transaction = transactionRecord;
  }

  @action
  onComplete() {
    // Temporary record while API creates actual resource
    // It will be replaced by the actual resource at next page load
    let { claimType, selectedTransactions } = this.dataContext;
    this.store.createRecord('claim', {
      type: claimType,
      status: CLAIM_STATUSES.review,
      transactionIds: selectedTransactions.map(transaction => transaction.id),
    });

    let { organization } = this.organizationManager;
    this.router.transitionTo('transactions.index', organization, {
      queryParams: { ...this.dataContext.queryParams, highlight: this.dataContext.transaction.id },
    });
  }

  onAbortTask = dropTask(async () => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('dispute-flow.abandon.title'),
      description: this.intl.t('dispute-flow.abandon.description'),
      cancel: this.intl.t('dispute-flow.abandon.cta.cancel'),
      confirm: this.intl.t('dispute-flow.abandon.cta.confirm'),
    });

    if (result === 'confirm') {
      let { organization } = this.organizationManager;

      this.router.transitionTo('transactions.index', organization, {
        queryParams: {
          ...this.dataContext.queryParams,
          highlight: this.dataContext.transaction.id,
        },
      });

      return true;
    }

    return false;
  });
}
