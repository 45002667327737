export default {
  "bank-accounts": "qWh l-app-content__wrapper",
  "header-wrapper": "qWf",
  "scrolled-top": "qWK",
  "header": "qWG l-app-content__page-header",
  "create-account-button": "qWr btn btn--primary",
  "main": "qWb",
  "accounts": "qWy",
  "subtitle-container": "qWU",
  "subtitle": "qWj title-3 mb-16",
  "amount": "qWW body-2",
  "list--cashbeeSavings": "qWu"
};
