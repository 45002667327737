import CashFlowCategoriesIndexRoute from '../index/route';

export default class CashFlowCategoriesTypeRoute extends CashFlowCategoriesIndexRoute {
  templateName = 'cash-flow-categories/index';

  beforeModel(transition) {
    let type = transition.to.params.type;

    if (!['inflows', 'outflows'].includes(type)) {
      this.transitionTo('cash-flow-categories.type', 'inflows');
    }
  }
}
