export default {
  "container": "ZSp",
  "content": "ZSx",
  "setup-box": "ZSw",
  "setup-wizard": "ZSN",
  "setup-success": "ZSv",
  "setup-success-lottie": "ZSo",
  "setup-close-btn": "ZSn",
  "tray-connection": "ZSi",
  "close-button": "ZSA"
};
