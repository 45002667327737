export default {
  "container": "qbU",
  "content": "qbj",
  "logos": "qbW",
  "logo": "qbu",
  "animation": "qyS",
  "title": "qyc title-2 mb-32",
  "avatar": "qyq",
  "actions": "qyZ mb-32",
  "footer": "qyR body-2"
};
