import { variation } from 'ember-launch-darkly';

export const subscriptionClose = {
  steps: {
    introduction: {
      componentName: 'subscription/close/introduction',
      nextStepId: ({ isEligibleForVoucher }) => {
        let isVoucherFeatureEnabled = variation('feature--improve-retention-vouchers-ab-test');
        return isVoucherFeatureEnabled && isEligibleForVoucher ? 'voucher' : 'reason-category';
      },
    },
    voucher: {
      componentName: 'subscription/close/voucher',
      nextStepId: ({ continueClosure }) => {
        let isVoucherFeatureEnabled = 'feature-–improve-retention-vouchers-ab-test';
        if (isVoucherFeatureEnabled && !continueClosure) {
          return 'success-retention';
        }
        return 'reason-category';
      },
    },
    'reason-category': {
      componentName: 'subscription/close/reason-category',
      nextStepId: ({ closingReasonCategory }) => {
        if (closingReasonCategory?.key === 'other') {
          return 'reason-details';
        } else if (closingReasonCategory?.items?.length) {
          return 'reason';
        }
        return 'disclaimer';
      },
    },
    'reason-details': {
      componentName: 'subscription/close/reason-details',
      nextStepId: 'disclaimer',
    },
    reason: {
      componentName: 'subscription/close/reason',
      nextStepId: 'disclaimer',
    },
    disclaimer: {
      componentName: 'subscription/close/disclaimer',
      nextStepId: 'success',
    },
    confirm: {
      componentName: 'subscription/close/confirm',
      nextStepId: 'success',
    },
    success: {
      componentName: 'subscription/close/success',
    },
    'success-retention': {
      componentName: 'subscription/close/success-retention',
    },
  },
  options: {
    enablePersistence: false,
  },
};
