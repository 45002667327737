export default {
  "member-details": "qfd",
  "header": "qfI",
  "header-img": "qft",
  "invited-persona": "qfM",
  "revoked-persona": "qfP",
  "invited-icon": "qfl",
  "revoked-icon": "qfX",
  "dropdown": "qfC",
  "header-body": "qfk",
  "name": "qfJ title-3",
  "email": "qfs caption-bold",
  "actions": "qfF body-2",
  "actions-cards": "qfg",
  "actions-transactions": "qfD",
  "body": "qfV",
  "expense-permissions-section": "qfL",
  "body-title": "qfT title-4",
  "body-attr": "qfz",
  "body-label": "qfa",
  "attr-buttons": "qfH",
  "body-role": "qfO small",
  "details-actions": "qfm",
  "actions-item": "qfp",
  "disclaimer": "qfx",
  "permissions": "qfw",
  "permission": "qfN",
  "permission-label": "qfv",
  "permission-check": "qfo",
  "permission-missing": "qfn",
  "editable-section": "qfi"
};
