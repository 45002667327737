export default {
  "container": "qjR",
  "form-container": "qjQ",
  "preview-container": "qje",
  "pdf-preview-container": "qjB",
  "content": "qjE",
  "title": "qjd",
  "tabs-container": "qjI",
  "close-button": "qjt btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qjM",
  "header": "qjP",
  "without-tabs": "qjl"
};
