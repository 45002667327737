export default {
  "supplier-invoices-page-header": "ZZF",
  "filters-container": "ZZg",
  "search-bar": "ZZD",
  "filters": "ZZV",
  "bulk-actions": "ZZL",
  "item-count": "ZZT",
  "higher-index": "ZZz",
  "title-wrapper": "ZZa",
  "title": "ZZH",
  "subtitle": "ZZO",
  "table-container": "ZZm",
  "file-dropzone": "ZZp",
  "dropzone-visible": "ZZx",
  "header-actions": "ZZw",
  "header-wrapper": "ZZN"
};
