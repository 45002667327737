export default {
  "container": "qjY",
  "form-container": "qjh",
  "preview-container": "qjf",
  "pdf-preview-container": "qjK",
  "content": "qjG",
  "title": "qjr",
  "tabs-container": "qjb",
  "close-button": "qjy btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qjU",
  "header": "qjj",
  "without-tabs": "qjW"
};
