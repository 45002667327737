export default {
  "mt-16": "quB",
  "title": "quE",
  "avatar": "qud",
  "edit-organization-form": "quI",
  "dropdown-content": "qut",
  "city-block": "quM",
  "zip-code-input": "quP",
  "cancel-button": "qul",
  "error-message": "quX",
  "nature-of-operations": "quC",
  "error": "quk",
  "legal-code": "quJ",
  "activity-type": "qus"
};
