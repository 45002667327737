import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

const MIN_AMOUNT_REQUIRED = 50;
const MAX_AMOUNT_REQUIRED = 1000;

export default class TopupAmountController extends Controller {
  @service homePage;
  @service intl;
  @service organizationManager;
  @service payment;
  @service router;
  @service segment;
  @service sentry;

  amountShortcuts = ['100.00', '300.00', '500.00', '1000.00'];

  @tracked errorMessage = '';
  @tracked amount;

  get isAfterKyc() {
    return this._isKycCompleted;
  }

  handleOnCloseTask = dropTask(async () => {
    let membership = this.organizationManager.membership;
    membership.topupsStatus = 'skipped';
    try {
      await membership.save();
    } catch (error) {
      // We should not prevent a user to close the topup flow evenif
      // updating the topupsStatus fails
      this.sentry.captureException(error);
    }
    this.homePage.visitDefaultPage(this.organizationManager.organization);
  });

  @action
  updateAmount(value) {
    this.amount = value;
    this.errorMessage = null;
    this.selectAmount = null;
  }

  validateAmountExists() {
    if (!this.amount) {
      this.errorMessage = this.intl.t('on-boarding.top-up.error.amount-invalid');
      return false;
    }
    this.errorMessage = null;
    return true;
  }

  validateAmountMinimum() {
    if (this.amount < MIN_AMOUNT_REQUIRED) {
      this.errorMessage = this.intl.t('on-boarding.top-up.error.amount-minimum');
      return false;
    }
    this.errorMessage = null;
    return true;
  }

  validateAmountMaximum() {
    if (this.amount > MAX_AMOUNT_REQUIRED) {
      this.errorMessage = this.intl.t('on-boarding.top-up.error.amount-maximum');
      return false;
    }
    this.errorMessage = null;
    return true;
  }

  validateAmount() {
    return (
      this.validateAmountExists() && this.validateAmountMinimum() && this.validateAmountMaximum()
    );
  }

  @action
  nextStep(event) {
    event.preventDefault();

    let validAmount = this.validateAmount();

    if (validAmount) {
      this.payment.amount = this.amount;
      this.segment.track('onboarding_top_up_amount_filled', { origin: 'onboarding' });
      this.router.transitionTo('onboarding.topup.payment');
    }
  }
}
