import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import {
  IN_PROGRESS_FINANCING_STATUSES,
  IN_PROGRESS_INSTALLMENT_STATUSES,
} from 'qonto/constants/financing';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FinancingPayLaterInProgressRoute extends Route {
  @service financing;
  @service sentry;
  @service organizationManager;
  @service store;

  model() {
    this.fetchFinancingsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      fetchFinancingsTask: this.fetchFinancingsTask,
    };
  }

  fetchFinancingsTask = dropTask(async () => {
    if (variation('feature--boolean-pay-later-financing-status')) {
      return await this.financing.fetchFinancings(IN_PROGRESS_FINANCING_STATUSES);
    } else {
      return await this.store.query('financing', {
        organization_id: this.organizationManager.organization.id,
        per_page: 500,
        page: 1,
        filter: { installment_status: IN_PROGRESS_INSTALLMENT_STATUSES },
        sort_by: 'scheduled_at:asc',
      });
    }
  });
}
