export default {
  "guests": "qfA",
  "guests--upsell-screen": "qfY",
  "no-members": "qfh",
  "teams-header": "qff",
  "empty": "qfK",
  "invite-text": "qfG",
  "body": "qfr",
  "members": "qfb",
  "members-list": "qfy",
  "members-title": "qfU caption-bold",
  "member": "qfj",
  "details": "qfW"
};
