export default {
  "page-wrapper": "qja",
  "content-wrapper": "qjH",
  "content": "qjO",
  "period-field": "qjm",
  "format-options": "qjp",
  "format-option": "qjx",
  "format-radio": "qjw",
  "format-description": "qjN body-2"
};
