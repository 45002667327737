export default {
  "container": "qUm",
  "form-container": "qUp",
  "title": "qUx",
  "title-border": "qUw",
  "tabs-container": "qUN",
  "preview-container": "qUv",
  "email-preview-container": "qUo",
  "close-button": "qUn btn btn--icon-only btn--tertiary btn--large"
};
