export default {
  "page-container": "qGA",
  "form": "qGY",
  "close-button": "qGh btn btn--icon-only btn--tertiary btn--large",
  "preview": "qGf",
  "preview-padding": "qGK",
  "title": "qGG",
  "header": "qGr",
  "without-tabs": "qGb",
  "form-section": "qGy",
  "form-footer": "qGU",
  "disclaimer-text": "qGj"
};
