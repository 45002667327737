import { NotFoundError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { COMMON_QUOTE_EXPIRY_PERIODS } from 'qonto/components/receivable-invoices/form/due-date-selector';
import CURRENCIES from 'qonto/constants/currencies';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { MIGRATION_CUT_OFF_DATE } from 'qonto/constants/products';
import { differenceInCalendar } from 'qonto/utils/date';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class QuotesDuplicateRoute extends Route {
  @service abilities;
  @service intl;
  @service toastFlashMessages;
  @service menu;
  @service organizationManager;
  @service localeManager;
  @service router;
  @service store;
  @service sentry;

  beforeModel() {
    if (!this.abilities.can('read receivableInvoice')) {
      return this.router.replaceWith('receivable-invoices');
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model({ id }, transition) {
    let { organization } = this.organizationManager;

    let settings, documentItems;

    try {
      settings = await this.store.findRecord('receivable-invoices-settings', organization.id);

      documentItems = await this.fetchDocumentItemsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => this.ignoreNotFoundAndHandleError(error));
    } catch (error) {
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }

      if (error instanceof NotFoundError) {
        return;
      }
      this._redirectOnError();
    }

    try {
      await this.fetchOrganizationAvatarTask.perform(organization);
    } catch (error) {
      this.handleError(error);
    }

    let quote = await this.store.findRecord('quote', id);

    let {
      beneficiaryName,
      contactEmail,
      termsAndConditions,
      welfareFund,
      withholdingTax,
      stampDutyAmount,
      items,
      header,
      footer,
      currency,
      organizationSnapshot,
    } = quote;

    let { locale } = this.localeManager;

    let issueDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);
    let lastExpiryDate = differenceInCalendar(quote.expiryDate, quote.issueDate, 'day');
    let daystoAdd = organization.legalCountry === 'FR' ? 90 : 30;
    let expiryDate = dayjs().add(daystoAdd, 'day').format(DATE_PICKER_FIELD_FORMAT);

    expiryDate = COMMON_QUOTE_EXPIRY_PERIODS.includes(lastExpiryDate)
      ? dayjs().add(lastExpiryDate, 'day').format(DATE_PICKER_FIELD_FORMAT)
      : expiryDate;

    // load customers into memory, will use peekAll later to display
    // they will be used for the customer selector possible values
    await this.store.query('customer', {
      filter: { organization_id: organization.id },
    });

    if (variation('feature--boolean-client-hub')) {
      await this.fetchClientsTask.perform().catch(ignoreCancelation).catch(this.handleError);
    }

    let customerRecord;
    if (quote.belongsTo('customer').id()) {
      if (variation('feature--boolean-client-hub')) {
        customerRecord = this.store.peekRecord('client-hub', quote.belongsTo('customer').id());
      } else {
        customerRecord = this.store.peekRecord('customer', quote.belongsTo('customer').id());
      }
    }

    let duplicateCurrency = customerRecord?.currency || currency || CURRENCIES.default;

    let duplicatedWelfareFund = this.store.createRecord('receivable-invoice/welfare-fund', {
      type: welfareFund?.type,
      rate: welfareFund?.rate,
    });

    let duplicatedWithholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
      type: withholdingTax?.type,
      rate: withholdingTax?.rate,
      reason: withholdingTax?.reason,
    });

    let duplicatedItems = [];

    items.forEach(item => {
      let {
        title,
        description,
        quantity,
        unitPrice,
        vatRate,
        vatExemptionCode,
        discount,
        unit,
        productId,
        links,
        type,
      } = item;
      duplicatedItems.push(
        this.store.createRecord('receivable-invoice/item', {
          title,
          description,
          quantity,
          unitPrice,
          vatRate,
          vatExemptionCode,
          discount,
          unit,
          productId,
          links,
          type,
        })
      );
    });

    if (!settings.contactEmail) {
      settings.contactEmail = this.organizationManager.membership.email;
    }
    contactEmail = settings.contactEmail;

    let nextNumber =
      settings?.numberingMode === 'automatic'
        ? settings.quoteNextNumberFormatted || settings.nextQuoteNumber
        : '';

    let duplicatedQuote = {};

    // when coming from the settings modal, there might be already one recorded quote in the store
    // instead of initializing a new one, the user will see the started one in the form
    if (transition?.from?.name === 'invoicing-settings' && this.peekRecordedQuotes.length > 0) {
      // only one newly created quote can be expected inside the array
      duplicatedQuote = this.peekRecordedQuotes[0];

      // the email needs to be the latest one
      duplicatedQuote.contactEmail = settings.contactEmail;
    } else {
      let duplicatedFreshInvoice = {
        number: nextNumber,
        beneficiaryName,
        customer: customerRecord,
        stampDutyAmount,
        issueDate,
        expiryDate,
        organization,
        items: duplicatedItems,
        locale,
        termsAndConditions,
        contactEmail,
        welfareFund: duplicatedWelfareFund,
        withholdingTax: duplicatedWithholdingTax,
        header,
        footer,
        organizationSnapshot,
        currency: duplicateCurrency,
      };

      duplicatedQuote = this.store.createRecord('quote', duplicatedFreshInvoice);
    }

    this.toastFlashMessages.toastInfo(
      this.intl.t('receivable-invoices.duplicate-quote.toast-success')
    );

    duplicatedQuote.organization.vatNumber = settings.vatNumber;

    if (organization.legalCountry === 'DE') {
      duplicatedQuote.header = settings.quoteHeader;
      duplicatedQuote.footer = settings.quoteFooter;
      duplicatedQuote.organization.taxNumber = settings.taxNumber;
      duplicatedQuote.organization.companyLeadership = settings.companyLeadership;
      duplicatedQuote.organization.districtCourt = settings.districtCourt;
      duplicatedQuote.organization.commercialRegisterNumber = settings.commercialRegisterNumber;
    }

    return { quote: duplicatedQuote, settings, documentItems };
  }

  get peekRecordedQuotes() {
    // peek in the store the already created but not saved quote without an id
    return this.store.peekAll('quote').filter(quote => quote.isNew && quote.id === null);
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchDocumentItemsTask = restartableTask(async () => {
    let documentItemsParams = {
      filter: {
        document_types: 'invoices,quotes,credit_notes',
        created_at_to: variation('feature-invoices-catalog-of-items')
          ? MIGRATION_CUT_OFF_DATE
          : undefined,
      },
      page: { size: 200, number: 1 },
      sort: '-total_documents,title,-unit_price',
    };
    let documentItems = await this.store.query('document-item', documentItemsParams);
    let total = documentItems.meta.total;

    while (total > documentItems.length) {
      documentItemsParams.page.number++;
      let nextDocumentItems = await this.store.query('document-item', documentItemsParams);
      documentItems = documentItems.concat(nextDocumentItems);
    }

    return documentItems;
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.router.transitionTo('quotes.index');
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }

  resetController() {
    super.resetController(...arguments);
  }
}
