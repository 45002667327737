export default {
  "main-container": "qiH",
  "main": "qiO",
  "main-wrapper": "qim",
  "close-button": "qip",
  "active-block": "qix",
  "title": "qiw",
  "list-options": "qiN",
  "card": "qiv",
  "card-container": "qio",
  "card-image": "qin",
  "card-tiles": "qii"
};
