import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class MandateFlowDataContext {
  mandate = null;
  hasReceivedMandate = null;
  hasMultipleActiveAccounts = null;

  constructor(mandate) {
    this.mandate = mandate;
  }
}

export default class MandateFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service store;

  constructor() {
    super(...arguments);

    let organization = this.organizationManager.organization;
    let bankAccount = this.organizationManager.currentAccount;

    let mandate = this.store.createRecord('mandate', {
      bankAccount,
      organization,
    });

    this.dataContext = new MandateFlowDataContext(mandate);

    Sentry.getCurrentScope().setTag('CFT', 'transfers');
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    let { organization, membership } = this.organizationManager;

    if (membership.shouldSubmitKyc && organization.kybPending) {
      return this.router.replaceWith('kyc.intro', {
        queryParams: {
          redirectRoute: 'mandates',
        },
      });
    }

    let bankAccountSlug = this.router.currentRoute.queryParams.bank_account;

    if (bankAccountSlug && bankAccountSlug !== this.dataContext.mandate.bankAccount.slug) {
      let { accounts } = this.organizationManager;
      let account = accounts.find(item => item.slug === bankAccountSlug);
      this.dataContext.mandate.set('bankAccount', account);
    }

    if (this.abilities.cannot('view mandate')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  onComplete() {
    this.router.transitionTo('mandates');
  }

  onAbortTask = dropTask(async () => {
    let result = await this.openAbortModalTask.perform();
    let shouldContinueAbort = result === 'confirm';
    if (shouldContinueAbort) {
      this.router.transitionTo('mandates');
    }
    return shouldContinueAbort;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('mandates.close_modal.title'),
      description: this.intl.t('mandates.close_modal.description'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.confirm'),
    });
  });

  beforeRestoreTask = dropTask(async ({ mandate }) => {
    let { attachments, organization } = mandate.relationships;

    if (attachments) {
      await this.store.query('attachment', {
        organization_id: organization.id,
        filters: { ids: attachments.map(({ id }) => id) },
        per_page: 500,
      });
    }
  });
}
