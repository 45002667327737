export default {
  "header": "quF",
  "content": "qug",
  "read-only": "quD",
  "document-collection-banner": "quV",
  "submit-banner": "quL",
  "review-banner": "quT",
  "disclaimer": "quz",
  "title": "qua",
  "card-container": "quH",
  "representatives-section": "quO",
  "disabled-href": "qum"
};
