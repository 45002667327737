import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import window from 'ember-window-mock';
const SUCCESS_EVENTS = ['SUPPLIER_ADDED', 'SUPPLIER_UPDATED'];

export default class GmiSetupRoute extends Route {
  @service segment;

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'connect');
    let status = transition.to.queryParams.status;

    window.opener?.postMessage({
      queryParamStatus: status,
      status: SUCCESS_EVENTS.includes(status) ? 'success' : 'aborted',
    });
  }
}
