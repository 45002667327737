export default {
  "container": "qUA",
  "form-container": "qUY",
  "preview-container": "qUh",
  "credit-note-preview-container": "qUf",
  "credit-note-preview": "qUK",
  "content": "qUG",
  "title": "qUr",
  "tabs-container": "qUb",
  "close-button": "qUy btn btn--icon-only btn--tertiary btn--large",
  "header": "qUU",
  "without-tabs": "qUj"
};
