export default {
  "mandates": "qrY",
  "mandates-empty": "qrh",
  "header": "qrf",
  "header-empty": "qrK",
  "header-content": "qrG",
  "search": "qrr",
  "search-bar": "qrb",
  "body": "qry",
  "isEmpty": "qrU",
  "left-section": "qrj",
  "mandates-list": "qrW",
  "mandate-suspended": "qru",
  "list-title": "qbS caption-bold",
  "list-empty": "qbc",
  "empty-illustration": "qbq",
  "empty-title": "qbZ title-3",
  "empty-description": "qbR body-2",
  "body-details": "qbQ",
  "l-app-content__page-header": "qbe",
  "pagination-footer": "qbB"
};
