import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

export default class ProductsRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');
    let canRead = this.abilities.can('read products');
    let isFFActive = variation('feature-invoices-catalog-of-items');

    if (!canRead || !isFFActive) {
      return this.homePage.replaceWithDefaultPage();
    }
  }
}
