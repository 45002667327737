export default {
  "signin": "ZqW",
  "form": "Zqu",
  "illustration": "ZZS",
  "purple": "ZZc",
  "mint": "ZZq",
  "mustard": "ZZZ",
  "peach": "ZZR",
  "form-wrapper": "ZZQ",
  "header-logo": "ZZe",
  "links": "ZZB",
  "no-account-label": "ZZE",
  "spinner-container": "ZZd",
  "main-image-container": "ZZI",
  "main-image": "ZZt"
};
