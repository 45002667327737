import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { PERIOD_KEYS } from 'qonto/constants/bookkeeping';
import { EXPORT_ERROR_TYPES, EXPORT_FORMATS } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

const MAX_INVOICES_EXPORT_LIMIT = 3000;

export default class SupplierInvoicesExportIndexController extends Controller {
  @service intl;
  @service segment;
  @service organizationManager;
  @service store;
  @service router;
  @service sentry;
  @service toastFlashMessages;

  @controller('supplier-invoices.export') exportController;

  @tracked selectedPeriod;
  @tracked format;
  @tracked separateFolders;

  get periodOptions() {
    return [
      {
        key: PERIOD_KEYS.THIS_MONTH,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.month'),
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
      },
      {
        key: PERIOD_KEYS.THIS_YEAR,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.year'),
        startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('year').format('YYYY-MM-DD'),
      },
      {
        key: PERIOD_KEYS.CUSTOM_PERIOD,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.custom.label'),
        startDate: null,
        endDate: null,
      },
    ];
  }

  get periodTrackingValue() {
    switch (this.selectedPeriod.key) {
      case PERIOD_KEYS.THIS_MONTH:
        return 'current_month';
      case PERIOD_KEYS.THIS_YEAR:
        return 'current_year';
      case PERIOD_KEYS.CUSTOM_PERIOD:
        return 'custom';
      default:
        return null;
    }
  }

  get showFormatSelection() {
    return this.formatOptions.length > 1;
  }

  get formatOptions() {
    return [
      this.organizationManager.organization.legalCountry === 'IT'
        ? {
            value: EXPORT_FORMATS.FATTURAPA,
            trackingValue: 'XML',
            label: 'XML',
            description: this.intl.t('invoices.export.modal.format.xml-description'),
          }
        : null,
      {
        value: EXPORT_FORMATS.PDF,
        trackingValue: 'PDF',
        label: this.intl.t('invoices.export.modal.format.original'),
      },
    ].filter(v => v);
  }

  @action
  handlePeriodUpdate(value) {
    this.selectedPeriod = value;
  }

  @action
  handleFormatUpdate(value) {
    this.format = value;
  }

  @action
  toggleSeparateFolders(separateFolders) {
    this.separateFolders = separateFolders;
  }

  trackExportRequest() {
    this.segment.track('supplier-invoices_export_requested', {
      period: this.periodTrackingValue,
      date_from:
        this.selectedPeriod.key === PERIOD_KEYS.CUSTOM_PERIOD
          ? this.selectedPeriod.startDate
          : null,
      date_to:
        this.selectedPeriod.key === PERIOD_KEYS.CUSTOM_PERIOD ? this.selectedPeriod.endDate : null,
      format: this.formatOptions.find(option => option.value === this.format)?.trackingValue,
      separate_folders: this.separateFolders,
    });
  }

  trackExportError(errorType) {
    this.segment.track('supplier-invoices_export_error', {
      error_type: errorType,
    });
  }

  exportTask = dropTask(async ({ dateFrom, dateTo, format, groupByStatuses }) => {
    this.trackExportRequest();

    try {
      await this.store
        .adapterFor('supplier-invoice')
        .export({ dateFrom, dateTo, format, groupByStatuses });

      this.router.transitionTo('supplier-invoices.export.success');
    } catch (error) {
      let handleAsGenericError = true;

      if (error.status === 422) {
        if (error.errors?.some(error => error.code === 'export_limit_reached')) {
          this.trackExportError(EXPORT_ERROR_TYPES.LIMIT_REACHED);
          this.toastFlashMessages.toastError(
            this.intl.t('invoices.export.modal.error.invoices-limit', {
              invoicesLimit: MAX_INVOICES_EXPORT_LIMIT,
            })
          );
          handleAsGenericError = false;
        } else if (error.errors?.some(error => error.code === 'empty_export')) {
          this.trackExportError(EXPORT_ERROR_TYPES.NO_INVOICES);
          this.toastFlashMessages.toastError(
            this.intl.t('invoices.export.modal.error.no-invoices')
          );
          handleAsGenericError = false;
        }
      }

      if (handleAsGenericError) {
        this.trackExportError(EXPORT_ERROR_TYPES.GENERIC_ERROR);
        this.toastFlashMessages.toastError(this.intl.t('invoices.export.modal.error.generic'));

        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }
  });
}
