import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InvoiceSubscriptionSddSetupRoute extends Route {
  @service menu;
  @service directDebitCollectionsManager;
  @service sentry;
  @service toastFlashMessages;
  @service intl;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  model() {
    let invoiceSubscription = this.modelFor('invoice-subscriptions.sdd');

    this.fetchMandateListTask
      .perform(invoiceSubscription?.customer.id)
      .catch(ignoreCancelation)
      .catch(this.handleMandateListError);

    return {
      invoiceSubscription,
      mandatesTask: this.fetchMandateListTask,
    };
  }

  fetchMandateListTask = dropTask(async customer_id => {
    return await this.directDebitCollectionsManager.loadMandates(customer_id);
  });

  @action
  handleMandateListError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }

  resetController(controller) {
    controller.reference = '';
    controller.referenceError = '';
    controller.mandateOptionSelected = true;
  }
}
