import { CLAIM_TYPES } from 'qonto/constants/transactions';

export const chargebackClaim = {
  steps: {
    'block-card': {
      componentName: 'chargeback-claim/block-card',
      nextStepId: 'transactions-selection',
    },
    'transactions-selection': {
      componentName: 'chargeback-claim/transactions-bulk-selection',
      nextStepId: ({ claimType }) =>
        claimType === CLAIM_TYPES.commercial ? 'upload-conversation' : 'upload-documents',
    },
    'upload-documents': {
      componentName: 'chargeback-claim/upload-documents',
      nextStepId: 'description',
    },
    'upload-conversation': {
      componentName: 'chargeback-claim/upload-conversation',
      nextStepId: 'upload-evidence',
    },
    'upload-evidence': {
      componentName: 'chargeback-claim/upload-evidence',
      nextStepId: 'upload-other',
    },
    'upload-other': {
      componentName: 'chargeback-claim/upload-other',
      nextStepId: 'description',
    },
    description: {
      componentName: 'chargeback-claim/description',
      nextStepId: 'success',
    },
    success: {
      componentName: 'chargeback-claim/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
