export default {
  "accounts": "qov",
  "header-subtitle": "qoo title-4",
  "subtitle-container": "qon",
  "subtitle": "qoi title-3 mb-16",
  "amount": "qoA body-2",
  "list--cashbeeSavings": "qoY",
  "connections-banner": "qoh",
  "list": "qof",
  "icon": "qoK",
  "badge": "qoG",
  "discover-tile": "qor"
};
