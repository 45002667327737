/* eslint-disable require-await */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class DeepLinksRoute extends Route {
  @service deviceManager;
  @service webviewManager;
  @service zendeskWidget;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'bookkeeping');

    let isMobileorWebview = this.deviceManager.isMobile || this.webviewManager.isWebview;

    if (isMobileorWebview) this.zendeskWidget.unmount();
  }

  async redirect(_model, transition) {
    super.redirect(...arguments);

    let { queryParams } = transition.to;
    let mobileOnlyRoutes = [
      'users.pre_approved_enrollment_token',
      'sca_recoveries.start',
      'users.sca_enrollment.start',
    ];

    if (mobileOnlyRoutes.includes(queryParams.action)) {
      return this.replaceWith('mobile-only');
    } else if (queryParams.action === 'users.verify_login_device') {
      let { verification_code } = queryParams;
      this.transitionTo('users.email-verification', verification_code);
    } else if (queryParams.action === 'overview.upload_documents') {
      let { organization_slug } = queryParams;
      this.replaceWith('upload-periodic-update-documents', organization_slug);
    } else if (queryParams.action === 'company_profile.upload_documents') {
      let { organization_slug } = queryParams;
      this.replaceWith('settings.company-profile', organization_slug);
    } else {
      this.replaceWith('protected-deeplinks', { queryParams });
    }
  }
}
