export default {
  "container": "qKv",
  "compact-header": "qKo",
  "visible": "qKn",
  "page-container": "qKi",
  "main": "qKA",
  "tabs": "qKY",
  "tabs-nav": "qKh",
  "tabs-panels": "qKf",
  "tabs-panel": "qKK body-2",
  "sidebar": "qKG",
  "prismic-content": "qKr"
};
