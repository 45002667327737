import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { AccountAggregationPromotionalPopup } from 'qonto/react/components/account-aggregation/promotional-popup';
import { BoiRefundPopup } from 'qonto/react/components/boi-refund-popup/boi-refund-popup';
import { KycPendingPopup } from 'qonto/react/components/kyc/kyc-pending-popup';
import { NrcPromotionalPopup } from 'qonto/react/components/nrc-promotional-popup';

export default class OverviewController extends Controller {
  queryParams = [{ bankAccounts: 'bank-accounts' }];

  @service router;
  @service abilities;
  @service segment;
  @service featuresManager;
  @service organizationManager;

  get hasSpainTaxesAbility() {
    return this.abilities.can('view nrc');
  }

  /**
   * Handles popup rendering.
   * Sort by priority.
   * Only one can be rendered per condition.
   * @returns {{reactComponent: () => React.ReactNode, props?: Record<any, any>}}}
   */
  get popup() {
    let { organization, membership } = this.organizationManager;

    if (this.featuresManager.isEnabled('boiRefundInfoModal')) {
      return {
        reactComponent: BoiRefundPopup,
      };
    }

    if (
      membership.kycPending &&
      // We only focus on owners
      organization.kybPending &&
      variation('feature--boolean-improve-modal-to-increase-kyc-rate')
    ) {
      return {
        reactComponent: KycPendingPopup,
      };
    }

    if (
      !organization.underRegistration &&
      this.hasSpainTaxesAbility &&
      variation('feature--boolean-nrc-payments-promo-signed-up-users')
    ) {
      return {
        reactComponent: NrcPromotionalPopup,
      };
    }

    if (variation('feature--boolean-account-aggregation-promo-popup')) {
      return {
        reactComponent: AccountAggregationPromotionalPopup,
      };
    }
  }

  get createTransfer() {
    let isQontoPilotFeatureEnabled = this.featuresManager.isEnabled('qontoPilot');
    let canUseQontoPilot = this.abilities.can('use qonto-pilot');
    let canCreateTransfer = this.abilities.can('create transfer');

    if (isQontoPilotFeatureEnabled) {
      if (canUseQontoPilot) {
        return false;
      } else {
        return canCreateTransfer && !canUseQontoPilot;
      }
    } else {
      return canCreateTransfer;
    }
  }

  @action
  redirectToTransferPage() {
    this.segment.track('overview_create_transfer_clicked');
    return this.router.transitionTo('transfers.new');
  }
}
