export default {
  "container": "Zcr",
  "security-method": "Zcb",
  "icon": "Zcy",
  "body": "ZcU",
  "status": "Zcj body-2",
  "device": "ZcW",
  "device-icon": "Zcu",
  "subtitle": "ZqS body-2",
  "current": "Zqc"
};
