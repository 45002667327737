export default {
  "splash-landing": "qiM",
  "container": "qiP",
  "options": "qil",
  "physical": "qiX",
  "card-label": "qiC",
  "physical-cta-container": "qik",
  "physical-cta": "qiJ",
  "coming-soon": "qis"
};
