export default {
  "page-header": "ZQc",
  "header-breadcrumb": "ZQq",
  "tabs-container": "ZQZ",
  "bottom-border": "ZQR",
  "tabs-separator": "ZQQ",
  "previous-request": "ZQe",
  "body-link": "ZQB",
  "border-top": "ZQE"
};
