export default {
  "dropdown-icon": "ZRI",
  "dropdown": "ZRt",
  "download-button": "ZRM",
  "connections-banner": "ZRP",
  "recommendation-cards": "ZRl",
  "wrapper-with-cards": "ZRX",
  "transactions-wrapper": "ZRC",
  "separator": "ZRk",
  "with-transaction": "ZRJ",
  "annual-billing-banner": "ZRs",
  "mt-132": "ZRF",
  "transactions-sidebar": "ZRg",
  "empty-state-wrapper": "ZRD"
};
