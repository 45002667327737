import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { compare } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { CARD_FLOWS_TRACKING_ORIGINS, CARD_LOCK_REASONS, ORIGIN } from 'qonto/constants/cards';
import { getEmptyStateConfig } from 'qonto/constants/empty-states/cards';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

const DEFAULT_SORT_BY = 'status:asc';
const DEFAULT_FILTERS = {
  statuses: [],
  card_levels: [],
  holder_id: '',
  team_ids: [],
};

export const HIDDEN_CARD_NUMBERS = {
  pan: null,
  cvv: null,
  isRevealed: false,
};

export default class CardsIndexController extends Controller {
  @service abilities;
  @service emptyStates;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service notifierCounterManager;
  @service organizationManager;
  @service router;
  @service segment;
  @service subscriptionManager;

  ORIGIN = ORIGIN;

  @reads('organizationManager.organization') organization;
  @reads('organizationManager.organization.slug') organizationSlug;
  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  queryParams = [
    'card_levels',
    'holder_id',
    'statuses',
    'team_ids',
    'highlight',
    'page',
    'per_page',
    'sort_by',
    'query',
    { bankAccounts: 'bank-accounts' },
  ];

  @tracked card_levels = DEFAULT_FILTERS.card_levels;
  @tracked holder_id = DEFAULT_FILTERS.holder_id;
  @tracked statuses = DEFAULT_FILTERS.statuses;
  @tracked team_ids = DEFAULT_FILTERS.team_ids;
  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked sort_by = DEFAULT_SORT_BY;
  @tracked query = '';
  @tracked bankAccounts = '';
  @tracked cardNumbers = HIDDEN_CARD_NUMBERS;

  get filters() {
    return {
      card_levels: this.card_levels,
      holder_id: this.holder_id,
      statuses: this.statuses,
      team_ids: this.team_ids,
    };
  }

  get isEmptyLocally() {
    return this.cards.length === 0;
  }

  get isEmptyGlobally() {
    return this.model.isEmptyGlobally;
  }

  get shouldShowDiscoverEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get shouldShowInformEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get emptyStateRevampOptions() {
    if (this.model.fetchCardsTask.isRunning || this.model.fetchCardsTask.last.isError) {
      return;
    }

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: Boolean(this.query) || this.hasFilters,
      config: getEmptyStateConfig(this.intl),
      customInputs: {
        tab: this.router.currentRouteName.replace('cards.', ''),
        isSubAccountClosed: Boolean(this.isAccountClosed),
      },
      abilities: {
        canRequestCards: this.requestCardAbility,
        canCreateCards: this.createCardAbility,
        canReadCards: this.abilities.can('read card'),
        canReadBankAccount: this.abilities.can('read bankAccount'),
        hasMultiAccountsFeature: this.subscriptionManager.features.multiAccounts,
        hasTeamManagementFeature: this.subscriptionManager.features.teamManagement,
      },
    });
  }

  get cards() {
    return this.model.fetchCardsTask.lastSuccessful?.value || [];
  }

  get hasFilters() {
    return Object.entries(this.filters).some(([k, v]) => compare(DEFAULT_FILTERS[k], v));
  }

  /*
   * The queryParameters that should be passed from tab to tab
   * when navigating between the different cards tabs,
   * For instance, it allows to persist the selected bank-account
   * in the header.
   */
  get tabToTabQueryParams() {
    return { 'bank-accounts': this.bankAccounts };
  }

  get selectedBankAccount() {
    let { accounts } = this.organizationManager;
    /*
     * Note that bank-accounts query param is able to contain several accounts ids separated by a coma.
     * It means that if bank-accounts is currently specified, the corresponding account can be found only if
     * its current value corresponds to one unique account (and not multiple accounts separated by a coma)
     */
    return (
      accounts.find(({ id }) => id === this.bankAccounts) || this.organizationManager.currentAccount
    );
  }

  get localState() {
    if (this.model.fetchCardsTask.isRunning) {
      return {
        error: false,
        isLoading: true,
      };
    }

    if (this.model.fetchCardsTask.last.isError) {
      return {
        error: true,
        isLoading: false,
      };
    }

    return {
      error: false,
      isLoading: false,
    };
  }

  get shouldDisplayEmptyState() {
    return (
      !this.model.fetchCardsTask.isRunning &&
      !this.model.fetchCardsTask.last.isError &&
      !this.cards.length
    );
  }

  get shouldShowFilters() {
    return !this.shouldShowInformEmptyState || this.hasFilters || this.query;
  }

  get shouldShowNotification() {
    return this.notifierCounterManager.counter?.cardRequests > 0;
  }

  get requestCardAbility() {
    return this.abilities.can('read request') && this.abilities.can('createCard request');
  }

  get createCardAbility() {
    return this.abilities.can('create card');
  }

  get isAccountClosed() {
    return this.selectedBankAccount?.isClosed;
  }

  get hasMultipleActiveAccounts() {
    return this.organization.hasMultipleActiveAccounts;
  }

  get authorizedBalance() {
    return this.organization?.mainAccount?.authorizedBalance;
  }

  get isItalianOrga() {
    return (
      variation('operational--boolean-disable-card-creation-for-italy') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  lockCardTask = dropTask(async (close, { card, reason }) => {
    try {
      await card.lock(reason);
      this.toastFlashMessages.toastInfo(this.intl.t('toasts.cards.lock'));
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      close();
    }
  });

  deleteCardTask = dropTask(async (closeModal, { card }) => {
    try {
      await card.delete();
      this.segment.track('card_deleted_confirmed', { card_type: card.cardLevel });
      let message = this.intl.t('toasts.cards.delete');
      this.toastFlashMessages.toastInfo(message);
    } finally {
      closeModal();
    }
  });

  searchTask = restartableTask(async query => {
    await timeout(DEBOUNCE_MS);
    this.query = query;
  });

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  get closeSidebarId() {
    return 'close-sidebar';
  }

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  @action
  handleFilter(filter, value) {
    this[filter] = value;
    this.page = 1;
  }

  @action
  resetQueryParams() {
    this.card_levels = [];
    this.holder_id = '';
    this.statuses = [];
    this.team_ids = [];
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
    this.query = '';
  }

  @action
  toggleRevealCardNumbers({ pan, cvv, isRevealed }) {
    this.cardNumbers = { pan, cvv, isRevealed };
  }

  @action
  afterHighlight(card) {
    if (card) {
      this.segment.track('card_side_panel_open', { card_type: card.cardLevel });
      this.toggleRevealCardNumbers(HIDDEN_CARD_NUMBERS);
    }
  }

  @action
  trackCtaEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'cards',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  @action
  handleCreateCardClick(origin) {
    if (this.isItalianOrga) {
      this.segment.track('cards_order_clicked', {
        origin: CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR,
      });
      return this.modals.open('card/modals/bank-of-italy-restrictions');
    }

    // ES(?) tracking
    this.trackCtaEvent(origin);
    // Cards-dedicated tracking
    this.segment.track('cards_order_clicked', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR,
    });

    this.router.transitionTo('cards.physical-or-virtual', {
      queryParams: { origin: ORIGIN.CREATION_REGULAR },
    });
  }

  @action
  handleRequestCardClick(origin) {
    if (this.isItalianOrga) {
      this.segment.track('cards_order_clicked', {
        origin: CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR,
      });
      return this.modals.open('card/modals/bank-of-italy-restrictions');
    }

    this.trackCtaEvent(origin);

    this.router.transitionTo('requests.landing', {
      queryParams: {
        origin: 'cards',
      },
    });
  }

  @action
  showLockCardModal(card) {
    this.modals.open('popup/destructive', {
      title: this.intl.t('cards.modals.lock.title'),
      description: this.intl.t('cards.modals.lock.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.cards.lock'),
      confirmTask: this.lockCardTask,
      card,
      reason: CARD_LOCK_REASONS.LOCK,
    });
  }

  @action
  showDeleteCardModal(card) {
    this.segment.track('card_deleted_clicked', { card_type: card.cardLevel });
    this.modals.open('popup/destructive', {
      title: this.intl.t('cards.modals.delete.title'),
      description: `${this.intl.t('cards.modals.delete.description')} ${this.intl.t(
        'cards.modals.delete.reminder'
      )}`,
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      confirmTask: this.deleteCardTask,
      card,
    });
  }
}
