import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { didCancel, restartableTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

function getSearchParams(params) {
  let { page, perPage, sortBy, query, labelIds, activityTag, vatRates, missingVAT, missingLabels } =
    params;

  let filters = {
    hidden: false,
    sct: true,
    label_ids: labelIds,
    vat_rates: vatRates,
  };

  if (activityTag) {
    filters.activity_tag = activityTag;
  }

  // missingVAT and missingLabels behave as an OR
  // they are two options on the same dropdown filter
  if (missingVAT && missingLabels) {
    filters.missing_vat_or_labels = true;
  } else if (missingVAT) {
    filters.has_vat_rate = false;
  } else if (missingLabels) {
    filters.has_labels = false;
  }

  return {
    page,
    per_page: perPage,
    query,
    filters,
    sort_by: sortBy,
  };
}

export default class CounterpartiesIndexRoute extends Route {
  @service abilities;
  @service organizationManager;
  @service router;
  @service store;
  @service toastFlashMessages;
  @service sentry;
  @service intl;
  @service homePage;

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    query: { refreshModel: true },
    activityTag: { refreshModel: true },
    labelIds: { refreshModel: true },
    missingVAT: { refreshModel: true },
    missingLabels: { refreshModel: true },
    vatRates: { refreshModel: true },
    sortBy: { refreshModel: true },
  };

  beforeModel() {
    if (this.abilities.cannot('navigate counterparty')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (this.abilities.cannot('access counterparty')) {
      return this.router.replaceWith('counterparties.upgrade-plan');
    }
  }

  model(params) {
    this.params = getSearchParams(params);

    this.searchTask.perform().catch(error => {
      if (!didCancel(error)) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      }
    });

    this.fetchAllSuppliers.perform().catch(error => {
      if (!didCancel(error)) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      }
    });

    return { searchTask: this.searchTask, fetchAllSuppliers: this.fetchAllSuppliers };
  }

  searchTask = restartableTask(async () => {
    return await this.store.query('beneficiary', {
      organization_id: this.organizationManager.organization.id,
      ...this.params,
    });
  });

  fetchAllSuppliers = restartableTask(async () => {
    // fetching customPermissions used in controller for emptyStateRevampOptions
    let { membership } = this.organizationManager;
    if (membership.manager && !membership.customPermissions) {
      await membership.getRole();
    }
    return await this.store.query('beneficiary', {
      organization_id: this.organizationManager.organization.id,
    });
  });

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }
}
