import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { USER_ACTIONS_STATUS } from 'qonto/constants/user-actions';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InsuranceHubPoliciesRoute extends Route {
  @service router;
  @service store;
  @service organizationManager;
  @service segment;
  @service sentry;

  model() {
    this.dismissInsuranceDiscoveryCardTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    this.fetchInsuranceContractsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      fetchInsuranceContractsTask: this.fetchInsuranceContractsTask,
    };
  }

  dismissInsuranceDiscoveryCardTask = dropTask(async () => {
    let insuranceHubAction;

    if (variation('feature--improve-product-discovery')) {
      // WARNING: This can create bugs (eg duplicated user-actions)
      // We should use the productDiscovery.fetchUserActions method
      // instead of querying the store directly.
      await this.store.query('user-action-v2', {});
      insuranceHubAction = this.store
        .peekAll('user-action-v2')
        .find(
          ({ key, status }) =>
            key === 'insurance-hub-action' && status !== USER_ACTIONS_STATUS.DISMISSED
        );
    } else {
      // WARNING: This can create bugs (eg duplicated user-actions)
      // We should use the productDiscovery.fetchUserActions method
      // instead of querying the store directly.
      await this.store.query('user-action', {});
      insuranceHubAction = this.store
        .peekAll('user-action')
        .find(
          ({ name, status }) =>
            name === 'insurance-hub-action' && status !== USER_ACTIONS_STATUS.DISMISSED
        );
    }

    if (insuranceHubAction) {
      insuranceHubAction.status = USER_ACTIONS_STATUS.DISMISSED;
      await insuranceHubAction.save();
    }
  });

  fetchInsuranceContractsTask = dropTask(async () => {
    return await this.store.query('insurance-contract', {
      organization_id: this.organizationManager.organization.id,
    });
  });

  afterModel() {
    this.segment.track('insurance-hub_my-policies_displayed');
  }
}
