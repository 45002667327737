import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEFAULT_ELIGIBILITY } from 'qonto/constants/international-out/eligibility';

export default class TransferLandingRoute extends Route {
  @service abilities;
  @service errors;
  @service financing;
  @service homePage;
  @service internationalOutManager;
  @service organizationManager;
  @service router;
  @service toastFlashMessages;

  beforeModel(transition) {
    let canReadTransfers = this.abilities.can('read transfer');

    if (!canReadTransfers) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }

  async model() {
    return {
      internationalOut: await this.checkInternationalOutEligibility(),
      payLater: await this.checkPayLaterEligibility(),
    };
  }

  async checkInternationalOutEligibility() {
    try {
      return await this.internationalOutManager.getEligibilityStatus();
    } catch {
      return DEFAULT_ELIGIBILITY;
    }
  }

  async checkPayLaterEligibility() {
    if (this.abilities.cannot('create pay later transfer in financing')) {
      return null;
    }

    try {
      return await this.financing.checkPayLaterEligibility();
    } catch (error) {
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
      return null;
    }
  }
}
