import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TransferIndexRoute extends Route {
  @service router;

  beforeModel() {
    super.beforeModel(...arguments);
    this.router.transitionTo('transfers.pending');
  }
}
