export default {
  "vat-number": "Zct body-2",
  "banner": "ZcM",
  "container": "ZcP",
  "organization-profile": "Zcl",
  "input": "ZcX",
  "medium-input": "ZcC medium",
  "small-input": "Zck small",
  "actions": "ZcJ",
  "company-info": "Zcs",
  "company-info-section": "ZcF",
  "company-address-section": "Zcg",
  "company-country": "ZcD",
  "company-billing-email": "ZcV",
  "company-address": "ZcL"
};
