export default {
  "title": "Zcw title-3",
  "personal-profile-container": "ZcN",
  "personal-profile-section": "Zcv",
  "member-info-title": "Zco",
  "kyc-complete-profile": "Zcn",
  "info-fields": "Zci",
  "field": "ZcA",
  "address-fields": "ZcY",
  "field-span": "Zch",
  "input": "Zcf",
  "cancel": "ZcK"
};
