import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import {
  CONTRACT_STATUS,
  IN_PROGRESS_FINANCING_STATUSES,
  IN_PROGRESS_INSTALLMENT_STATUSES,
  PAY_LATER_ELIGIBILITY_STATUSES,
} from 'qonto/constants/financing';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FinancingPayLaterRoute extends Route {
  @service router;
  @service abilities;
  @service financing;
  @service userManager;
  @service flowLinkManager;
  @service sentry;

  async beforeModel({ to }) {
    if (this.abilities.cannot('access pay later in financing')) {
      return this.router.transitionTo('application');
    }

    let payLaterIntroItem = safeLocalStorage.getItem(
      `pay-later-intro-${this.userManager.currentUser.id}`
    );

    let response = await this.financing.checkPayLaterEligibility();

    let origin = to.queryParams?.origin || null;

    if (
      response.eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE &&
      response.contractStatus === CONTRACT_STATUS.NOT_SIGNED &&
      !payLaterIntroItem
    ) {
      return this.flowLinkManager.transitionTo({
        name: 'pay-later-application',
        stepId: 'intro',
        queryParams: { origin },
      });
    }
  }

  async model() {
    let [eligibility, insights] = await Promise.all([
      this.financing.getLastPayLaterEligibility(),
      this.financing.fetchRepaymentInsights(),
    ]);

    let tabCounts = {};

    try {
      if (variation('feature--boolean-pay-later-financing-status')) {
        tabCounts = await this.financing.fetchFinancingsTabInsights(
          IN_PROGRESS_FINANCING_STATUSES,
          'in_progress'
        );
      } else {
        tabCounts = await this.financing.fetchTabInsights({
          in_progress: IN_PROGRESS_INSTALLMENT_STATUSES,
        });
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    return { ...eligibility, ...insights, tabCounts };
  }
}
