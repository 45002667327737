export default {
  "page-wrapper": "ZZM",
  "content-wrapper": "ZZP",
  "content": "ZZl",
  "period-field": "ZZX",
  "format-options": "ZZC",
  "format-option": "ZZk",
  "format-radio": "ZZJ",
  "format-description": "ZZs body-2"
};
