import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class ReimbursementsIndexRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service organizationManager;
  @service segment;

  beforeModel() {
    if (this.abilities.cannot('navigate reimbursements approver view in request')) {
      return this.router.replaceWith('reimbursements.requests');
    }

    if (variation('feature--boolean-bulk-expense-payments-tabs')) {
      if (this.abilities.can('create transfer')) {
        return this.router.replaceWith('reimbursements.pending.to-pay');
      }
      return this.router.replaceWith('reimbursements.pending.to-approve');
    }

    return this.router.replaceWith('reimbursements.pending');
  }
}
