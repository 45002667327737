export default {
  "page-wrapper": "qAL",
  "container": "qAT",
  "spinner-container": "qAz",
  "left-panel": "qAa",
  "form": "qAH",
  "left-content": "qAO",
  "form-footer": "qAm",
  "delete-button": "qAp",
  "header": "qAx",
  "preview-container": "qAw",
  "email-preview": "qAN",
  "close-button": "qAv btn btn--icon-only btn--tertiary btn--large"
};
