export default {
  "container": "qju",
  "form-container": "qWS",
  "title": "qWc",
  "title-border": "qWq",
  "tabs-container": "qWZ",
  "preview-container": "qWR",
  "email-preview-container": "qWQ",
  "close-button": "qWe btn btn--icon-only btn--tertiary btn--large"
};
