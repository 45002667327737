export default {
  "body": "qbE",
  "members": "qbd",
  "members-results": "qbI",
  "members-list": "qbt",
  "members-title": "qbM caption-bold",
  "member": "qbP",
  "empty-illustration": "qbl",
  "empty-title": "qbX title-3",
  "empty-description": "qbC body-2",
  "invitable-members": "qbk",
  "details": "qbJ",
  "pagination-footer": "qbs"
};
