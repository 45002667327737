export default {
  "container": "ZSJ",
  "wrapper": "ZSs",
  "grey-header-block": "ZSF",
  "connect-app-header": "ZSg",
  "connect-app-header-link": "ZSD",
  "connect-app-header-content": "ZSV",
  "connect-app-header-cta": "ZSL",
  "connect-app-content": "ZST",
  "connect-app-description": "ZSz",
  "connect-app-body": "ZSa"
};
