export default {
  "container": "qha",
  "page-wrapper": "qhH",
  "header": "qhO",
  "back-button-wrapper": "qhm",
  "header-inner": "qhp",
  "header-main-wrapper": "qhx",
  "header-main": "qhw",
  "header-right": "qhN",
  "content-wrapper": "qhv",
  "content": "qho",
  "content-body": "qhn",
  "sidebar": "qhi"
};
