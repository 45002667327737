export default {
  "container": "ZSc",
  "link-grey": "ZSq",
  "slack-section-title": "ZSZ title-4 mb-8",
  "edit-channel-text": "ZSR body-2 mb-16",
  "rule-section-title": "ZSQ title-4 mb-16",
  "rule-cards-container": "ZSe",
  "edit-slack-container": "ZSB",
  "rule-card": "ZSE",
  "add-rule-container": "ZSd",
  "add-rule-link-button": "ZSI",
  "add-rule-text": "ZSt",
  "empty-state": "ZSM",
  "empty-state-textblock": "ZSP",
  "empty-state-title": "ZSl title-2",
  "empty-state-description": "ZSX body-2",
  "empty-state-illustration": "ZSC",
  "rules": "ZSk"
};
