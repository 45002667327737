import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { RECURRENCE } from 'qonto/constants/invoice-subscriptions';
import { MIGRATION_CUT_OFF_DATE } from 'qonto/constants/products';
import { STATUS } from 'qonto/constants/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class NewInvoiceSubscriptionRoute extends Route {
  @service abilities;
  @service menu;
  @service homePage;
  @service organizationManager;
  @service store;
  @service toastFlashMessages;
  @service intl;
  @service errors;
  @service sentry;
  @service router;
  @service flowLinkManager;
  @service directDebitCollectionsManager;

  @reads('organizationManager.organization') organization;

  settings;

  get isItalianOrganization() {
    return this.organization.legalCountry === 'IT';
  }

  get isGermanOrganization() {
    return this.organization.legalCountry === 'DE';
  }

  get isFrenchOrganization() {
    return this.organization.legalCountry === 'FR';
  }

  activate() {
    this.menu.hide();
  }

  async beforeModel({ from }) {
    if (this.abilities.cannot('create invoice-subscription')) {
      return this.homePage.visitDefaultPage();
    }

    let { organization, membership } = this.organizationManager;

    if (membership.shouldSubmitKyc && organization.kybPending) {
      return this.router.replaceWith('kyc.intro', {
        queryParams: {
          redirectRoute: 'invoice-subscriptions',
        },
      });
    }

    if (this.isItalianOrganization) {
      let eInvoiceActivation = await this.store.findRecord(
        'e-invoice-activation',
        this.organization.id
      );
      if (!eInvoiceActivation.eInvoicingActivated) {
        return this.flowLinkManager.transitionTo({
          name: 'invoice-onboarding',
          stepId: 'einvoice-activation',
          queryParams: { subscription: true, abortFallback: from.name },
        });
      }
    }

    this.settings = await this.store.findRecord(
      'receivable-invoices-settings',
      this.organization.id
    );
    if (this.settings?.numberingMode === 'manual') {
      this.settings.numberingMode = 'automatic';
      await this.settings.save();
      this.toastFlashMessages.toastInfo(
        this.intl.t('recurring-invoices.toasts.info.automatic-numbering-override')
      );
    }
  }

  async model(params, transition) {
    let { customerId } = params;
    // Fetch organization avatar
    await this.organization.getAvatar();

    // Prefetch customers
    await this.store.query('customer', {
      filter: { organization_id: this.organization.id },
    });

    if (variation('feature--boolean-client-hub')) {
      await this.fetchClientsTask.perform().catch(ignoreCancelation);
    }

    let customer;
    if (customerId) {
      customer = this.store.peekRecord('client-hub', customerId);
    }

    // Prefetch all items
    let documentItems = await this.fetchDocumentItemsTask.perform().catch(ignoreCancelation);

    // Prefetch french einvoicing settings
    let canCreateFrEinvoice = await this.fetchFrEinvoicingSettingsTask
      .perform()
      .catch(ignoreCancelation);

    let isFirstFrenchEinvoice = false;
    if (
      this.isFrenchOrganization &&
      canCreateFrEinvoice &&
      variation('feature--boolean-einvoicing-q2q')
    ) {
      await this.fetchFirstEInvoice();

      let allEinvoices = this.fetchFirstEInvoiceTask?.lastSuccessful?.value || false;

      isFirstFrenchEinvoice = allEinvoices && allEinvoices.length === 0;
    }

    let subscription;
    // when coming from the settings modal, there might be already one recorded subscription in the store
    // instead of initializing a new one, the user will see the started one in the form
    if (
      (transition?.from?.name === 'invoicing-settings' ||
        transition?.from?.params?.name === 'sdd-activation') &&
      this.peekRecordedSubscriptions.length > 0
    ) {
      // only one newly created subscription can be expected inside the array
      subscription = this.peekRecordedSubscriptions[0];

      // the fields need to be the latest one
      subscription.contactEmail = this.settings.contactEmail;
      subscription.header = this.isGermanOrganization ? this.settings.invoiceHeader : undefined;
      subscription.footer = this.isGermanOrganization ? this.settings.invoiceFooter : undefined;
    } else {
      let freshSubscription = {
        isEinvoice: (canCreateFrEinvoice && customer?.einvoicing) || false,
        contactEmail: this.settings.contactEmail,
        currency: CURRENCIES.default,
        startDate: dayjs().format(DATE_PICKER_FIELD_FORMAT),
        frequency: {
          value: 1,
          recurrence: RECURRENCE.MONTHLY,
        },
        paymentTermsDays: 15,
        organization: this.organization,
        items: [this.store.createRecord('receivable-invoice/item', defaultValues(this))],
        welfareFund: this.store.createRecord('receivable-invoice/welfare-fund'),
        withholdingTax: this.store.createRecord('receivable-invoice/withholding-tax'),
        payment: this.store.createRecord('receivable-invoice/payment', {
          conditions: this.isItalianOrganization ? 'TP02' : undefined,
          method: this.isItalianOrganization ? 'MP05' : undefined,
        }),
        header: this.isGermanOrganization ? this.settings.invoiceHeader : undefined,
        footer: this.isGermanOrganization ? this.settings.invoiceFooter : undefined,
        customer,
      };

      subscription = this.store.createRecord('invoice-subscription', freshSubscription);
    }

    // Read optional field from settings onto organization
    this.organization.vatNumber = this.settings.vatNumber;
    this.organization.taxNumber = this.settings.taxNumber;
    this.organization.companyLeadership = this.settings.companyLeadership;
    this.organization.districtCourt = this.settings.districtCourt;
    this.organization.commercialRegisterNumber = this.settings.commercialRegisterNumber;

    let sddActivation = this.fetchSddActivation();

    return {
      sddActivation,
      subscription,
      documentItems,
      settings: this.settings,
      canCreateFrEinvoice,
      isFirstFrenchEinvoice,
      loadMandatesTask: this.loadMandatesTask,
    };
  }

  get peekRecordedSubscriptions() {
    // peek in the store the already created but not saved subscription without an id
    return this.store
      .peekAll('invoice-subscription')
      .filter(
        subscription =>
          subscription.isNew &&
          subscription.id === null &&
          subscription.organization.id === this.organization.id
      );
  }

  deactivate() {
    this.menu.show();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.customerId = null;
      controller.origin = null;
    }
  }

  fetchSddActivation() {
    let { sddActivation } = this.modelFor('invoice-subscriptions');

    if (typeof sddActivation === 'undefined') {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      this.router.replaceWith('invoice-subscriptions.index');
    }
    return sddActivation;
  }

  fetchFrEinvoicingSettingsTask = dropTask(async () => {
    let canRead = this.abilities.can('read einvoicingSetting');

    if (!(this.isFrenchOrganization && canRead)) {
      return false;
    }
    try {
      let frEinvoicingSettings = await this.store.findRecord(
        'einvoicing-settings',
        this.organization.id
      );
      return frEinvoicingSettings?.einvoicingOnboarded;
    } catch {
      return false;
    }
  });

  loadMandatesTask = dropTask(async customerId => {
    try {
      let mandates = await this.directDebitCollectionsManager.loadMandates(customerId);

      return {
        mandates,
        isError: false,
      };
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return {
        mandates: [],
        isError: true,
      };
    }
  });

  @action
  error(error) {
    this.settings?.rollbackAttributes();

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.replaceWith('invoice-subscriptions');
  }

  fetchDocumentItemsTask = restartableTask(async () => {
    let documentItemsParams = {
      filter: {
        document_types: 'invoices,quotes,credit_notes',
        created_at_to: variation('feature-invoices-catalog-of-items')
          ? MIGRATION_CUT_OFF_DATE
          : undefined,
      },
      page: { size: 200, number: 1 },
      sort: '-total_documents,title,-unit_price',
    };
    let documentItems = await this.store.query('document-item', documentItemsParams);
    let total = documentItems.meta.total;

    while (total > documentItems.length) {
      documentItemsParams.page.number++;
      let nextDocumentItems = await this.store.query('document-item', documentItemsParams);
      documentItems = documentItems.concat(nextDocumentItems);
    }

    return documentItems;
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  @action
  fetchFirstEInvoice() {
    return this.fetchFirstEInvoiceTask.perform().catch(ignoreCancelation);
  }

  fetchFirstEInvoiceTask = restartableTask(async () => {
    let paramsWithOrganizationId = {
      filter: {
        organization_id: this.organizationManager.organization.id,
        status: `${STATUS.PAID},${STATUS.UNPAID},${STATUS.CANCELED}`,
        is_einvoice: true,
      },
      page: { number: 1, size: 25 },
    };

    try {
      let einvoicesArray = await this.store.query('receivable-invoice', paramsWithOrganizationId);

      return einvoicesArray;
    } catch (error) {
      if (error.status === 422) {
        return;
      }
      this.handleError(error);
    }
  });

  @action
  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }
}
