import { NotFoundError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask, restartableTask } from 'ember-concurrency';

import { STATUS } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class ReceivableInvoicesCreditNotesNewRoute extends Route {
  @service menu;
  @service organizationManager;
  @service store;
  @service toastFlashMessages;
  @service intl;
  @service router;
  @service sentry;

  async model(params, transition) {
    let { organization } = this.organizationManager;
    let { invoiceId } = params;

    try {
      await this.fetchOrganizationAvatarTask.perform(organization);
      let invoice = await this.store.findRecord('receivable-invoice', invoiceId);
      await this.store.query('customer', { filter: { organization_id: organization.id } });

      // Catch error if customer no longer exists
      await invoice.customer.catch(error => {
        invoice.customer = null;
        this.handleError(error);
      });

      let currency = invoice?.currency || 'EUR';

      if (![STATUS.PAID, STATUS.UNPAID, STATUS.CANCELED].includes(invoice.status)) {
        this._redirectOnWrongInvoice();
      }

      let { contactEmail, termsAndConditions, items, welfareFund, withholdingTax } = invoice;

      let duplicatedItems = items.map(
        ({
          title,
          description,
          quantity,
          unitPrice,
          vatRate,
          vatExemptionCode,
          discount,
          unit,
          productId,
          links,
          type,
        }) =>
          this.store.createRecord('receivable-invoice/item', {
            title,
            description,
            quantity: `${-quantity}`,
            unitPrice,
            vatRate,
            vatExemptionCode,
            discount,
            unit,
            productId,
            links,
            type,
          })
      );

      let duplicatedWelfareFund = this.store.createRecord('receivable-invoice/welfare-fund', {
        type: welfareFund?.type,
        rate: welfareFund?.rate,
      });

      let duplicatedWithholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
        type: withholdingTax?.type,
        rate: withholdingTax?.rate,
        reason: withholdingTax?.reason,
      });

      let settings, documentItems;

      try {
        settings = await this.store.findRecord('receivable-invoices-settings', organization.id);

        documentItems = await this.fetchDocumentItemsTask
          .perform()
          .catch(ignoreCancelation)
          .catch(error => this.ignoreNotFoundAndHandleError(error));
      } catch (error) {
        if (!(error instanceof NotFoundError) && ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }

      if (!settings.contactEmail) {
        settings.contactEmail = this.organizationManager.membership.email;
      }
      contactEmail = settings.contactEmail;

      let nextNumber =
        settings?.numberingMode === 'automatic'
          ? settings.creditNoteNextNumberFormatted || settings.nextCreditNoteNumber
          : '';

      documentItems = documentItems?.filter(item => item?.currency === currency) || [];

      let creditNote = {};

      if (
        transition?.from?.name === 'invoicing-settings' &&
        this.peekRecordedCreditNotes.length > 0
      ) {
        // only one newly created credit note can be expected inside the array
        creditNote = this.peekRecordedCreditNotes[0];

        // the email needs to be the latest one
        creditNote.contactEmail = settings.contactEmail;
      } else {
        creditNote = this.store.createRecord('receivable-credit-note', {
          currency,
          number: nextNumber,
          items: duplicatedItems,
          organization,
          receivableInvoice: invoice,
          contactEmail,
          termsAndConditions,
          welfareFund: duplicatedWelfareFund,
          withholdingTax: duplicatedWithholdingTax,
        });
      }

      let receivableInvoice = await creditNote.get('receivableInvoice');

      return { creditNote, receivableInvoice, settings, documentItems };
    } catch (error) {
      this.handleError(error);
      this._redirectOnError();
    }
  }

  get peekRecordedCreditNotes() {
    // peek in the store the already created but not saved credit note without an id
    return this.store
      .peekAll('receivable-credit-note')
      .filter(creditNote => creditNote.isNew && creditNote.id === null);
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchDocumentItemsTask = restartableTask(async () => {
    let documentItemsParams = {
      filter: { document_types: 'invoices,quotes,credit_notes' },
      page: { size: 200, number: 1 },
      sort: '-total_documents,title,-unit_price',
    };
    let documentItems = await this.store.query('document-item', documentItemsParams);
    let total = documentItems.meta.total;

    while (total > documentItems.length) {
      documentItemsParams.page.number++;
      let nextDocumentItems = await this.store.query('document-item', documentItemsParams);
      documentItems = documentItems.concat(nextDocumentItems);
    }

    return documentItems;
  });

  setupController(controller, model) {
    super.setupController(controller, model);
  }

  resetController(controller, isExiting, transition) {
    if (
      !transition.targetName.includes('credit-notes.new') &&
      !transition.targetName.includes('invoicing-settings')
    ) {
      controller.model.creditNote.clearItemsWithNoId();
      if (isExiting && controller.model.creditNote.isNew) {
        this.store.unloadRecord(controller.model.creditNote);
      }
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  _redirectOnWrongInvoice() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
    this.router.transitionTo('receivable-invoices.index');
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices.index');
  }

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  ignoreNotFoundAndHandleError(error) {
    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.handleError(error);
    }
  }
}
