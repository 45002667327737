export default {
  "container": "ZqD",
  "header-cell": "ZqV caption-bold",
  "col-8": "ZqL",
  "table": "ZqT",
  "cell": "Zqz body-2",
  "cell-content": "Zqa",
  "row": "ZqH",
  "empty": "ZqO",
  "quick-actions": "Zqm",
  "card-infos": "Zqp",
  "card-digits": "Zqx",
  "empty-state": "Zqw"
};
