export default {
  "multi-transfer": "ZRO",
  "container": "ZRm",
  "title": "ZRp",
  "subtitle": "ZRx",
  "form-wrapper": "ZRw",
  "form": "ZRN",
  "form-title": "ZRv",
  "files-buttons": "ZRo",
  "files-buttons-item": "ZRn",
  "selected": "ZRi"
};
