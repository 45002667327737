import Controller from '@ember/controller';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/suppliers';
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SupplierIndexController extends Controller {
  @service emptyStates;
  @service intl;
  @service abilities;
  @service router;
  @service segment;

  queryParams = ['page', 'perPage', 'sortBy', 'status', 'search'];

  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = 'name:asc';
  @tracked status = SUPPLIER_STATUSES.UNARCHIVED;
  @tracked highlighted = null;
  @tracked search = '';

  get currentStatus() {
    if (!this.status) return SUPPLIER_STATUSES.UNARCHIVED;
    return this.status;
  }

  get suppliers() {
    return this.model.suppliersTask.lastSuccessful?.value[this.currentStatus] || [];
  }

  get hasNoResult() {
    return (
      this.search && !this.suppliers.length && !this.isError && !this.model.suppliersTask.isRunning
    );
  }

  get isError() {
    return this.model.suppliersTask.last.isError;
  }

  get hasError() {
    return this.model.suppliersTask.last?.isError;
  }

  get isEmptyLocally() {
    if (this.isError || this.model.suppliersTask.isRunning || this.suppliers.length !== 0)
      return false;
    let { archived, unarchived } = this.model.suppliersTask.lastSuccessful?.value ?? {};
    return !archived?.meta.total_count || !unarchived?.meta.total_count;
  }

  get displayGlobalEmptyState() {
    if (this.isError || this.search || this.model.suppliersTask.isRunning) return false;
    let { archived, unarchived } = this.model.suppliersTask.lastSuccessful?.value ?? {};
    return (
      !archived?.meta.total_count && !unarchived?.meta.total_count && this.suppliers.length === 0
    );
  }

  get isEmptyState() {
    return this.isEmptyLocally || this.displayGlobalEmptyState;
  }

  get emptyStateOptions() {
    if (this.isEmptyState) {
      let options = this.emptyStates.getEmptyStateOptions({
        isOrgEligibleForFeature: this.abilities.can('view supplier'),
        isEmptyGlobally: this.displayGlobalEmptyState || this.abilities.cannot('view supplier'),
        isEmptyLocally: this.isEmptyLocally || Boolean(this.search),
        config: getEmptyStateConfig(this.intl, {
          onClick: this.activationCta,
        }),
        hasActiveFilterOrSearch: Boolean(this.search),
        customInputs: {
          tab: this.currentStatus,
        },
      });
      return options;
    }
  }

  @action activationCta() {
    this.segment.track('add-supplier_clicked', { origin: 'supplier_list' });
    this.router.transitionTo('suppliers.new');
  }

  @action handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
  }

  @action
  retryFetchData() {
    let params = {
      status: this.status,
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
    };

    this.model.suppliersTask.perform(params).catch(ignoreCancelation);
  }

  @action
  changePage(page) {
    this.page = page;
  }

  @action
  changePerPage(perPage) {
    this.page = 1;
    this.perPage = perPage;
  }

  @action
  handleShowItem(supplier) {
    this.segment.track('view-supplier_clicked', { origin: 'supplier_list' });
    this.model.fetchRelatedInvoices(supplier.id);
    this.highlighted = supplier.id;
  }

  @action
  closeSidepanel() {
    this.highlighted = null;
  }

  searchSuppliers(query, resolve) {
    this.search = query;

    resolve(query);
  }

  @action handleSupplierSearch(query) {
    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this.searchSuppliers, query, resolve, reject, DEBOUNCE_MS);
    });
  }
}
