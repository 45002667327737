export default {
  "team": "qbF",
  "no-members": "qbg",
  "teams-header": "qbD",
  "header-search": "qbV",
  "search-bar": "qbL",
  "invite": "qbT body-2",
  "invite-text": "qbz",
  "empty-illustration": "qba",
  "empty-title": "qbH title-3",
  "empty-description": "qbO body-2",
  "pagination-footer": "qbm",
  "filter-search": "qbp",
  "fullsize": "qbx",
  "tabs-container": "qbw",
  "tabs-separator": "qbN",
  "tooltip-wrapper": "qbv",
  "invite-cta": "qbo"
};
