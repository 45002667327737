import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class FinancingPayLaterCompletedController extends Controller {
  @service router;

  @action
  refreshRoute() {
    this.router.refresh('financing.pay-later.completed');
  }

  get financings() {
    return this.model.fetchFinancingsTask.lastSuccessful?.value ?? [];
  }

  get localState() {
    let state = {
      isEmpty: false,
      isError: false,
    };

    if (this.model.fetchFinancingsTask.isRunning) return state;

    state.isError = this.model.fetchFinancingsTask.last.isError;
    state.isEmpty = this.financings.length === 0;

    return state;
  }
}
